import { action, makeAutoObservable, observable } from "mobx";
import { toast } from "react-toastify";
import { fetchRegister, fetchInviteLinkInfo } from "../network/fetchUser";
import { RegisterFormValidator } from "../helper/formValidation";
import { fetchLogout } from "../network/fetchUser";
import Login from "./login";
import jwt_decode from "jwt-decode";
import Cookies from "universal-cookie";
import {
  passwordReset,
  resetAdminPassword,
  resetUserPassword,
} from "../network/fetchAdmin";

class Register {
  cookies = new Cookies();
  username = "";
  email = "";
  password = "";
  currentPassword = "";
  confirmPassword = "";

  oldPassword = "";
  newPassword = "";
  confirmNewPassword = "";

  oldPasswordView = false;
  newPasswordView = false;
  confirmNewPasswordView = false;

  passView = false;
  confirmPassView = false;
  lid = "";
  inviteLinkInfo = null;
  userId = null;
  token = null;

  show = false;
  constructor() {
    makeAutoObservable(this, {
      username: observable,
      email: observable,
      password: observable,
      confirmPassword: observable,
      passView: observable,
      confirmPassView: observable,
      show: observable,
      lid: observable,
      inviteLinkInfo: observable,
      oldPassword: observable,
      newPassword: observable,
      confirmNewPassword: observable,
      oldPasswordView: observable,
      newPasswordView: observable,
      confirmNewPasswordView: observable,
      currentPassword: observable,

      _handleUsernameChange: action.bound,
      _handleEmailChange: action.bound,
      _handleOldPasswordChange: action.bound,
      _handleNewPasswordChange: action.bound,
      _handleConfirmNewPasswordChange: action.bound,
      _handlePasswordChange: action.bound,

      _handleConfirmPasswordChange: action.bound,
      _handlePassView: action.bound,
      _handleConfirmPassView: action.bound,
      _fetchRegister: action.bound,
      _fetchInviteLinkInfo: action.bound,
      _setLinkId: action.bound,
      _handleOldPasswordView: action.bound,
      _handleNewPasswordView: action.bound,
      _handleConfirmNewPasswordView: action.bound,
      _handleUserPasswordReset: action.bound,
      _handleAdminPasswordReset: action.bound,
      _handleSavePasswordReset: action.bound,
    });
  }

  _handleSavePasswordReset = async (userId) => {
    try {
      // const firstTimeUserId = jwt_decode(token).userId;

      // if (!firstTimeUserId) {
      //   console.error("User ID not found in token");
      //   return;
      // }
      const data = {
        u_id: userId,
        c_pass: this.oldPassword,
        n_pass: this.newPassword,
      };

      //   if (this.newPassword === "") {
      //     toast.error("Please enter a new password.");
      //     return;
      //   }

      if (this.newPassword !== this.confirmNewPassword) {
        toast.error("Confirm new Password does not match!");
        return;
      }

      if (!/\d/.test(this.newPassword)) {
        toast.error("New password should include numeric characters.");
        return;
      }

      if (!/[a-zA-Z]/.test(this.newPassword)) {
        toast.error("New password should include alphabetic characters.");
        return;
      }

      const response = await passwordReset(data);
      //   console.log("response --->", response);
      //   console.log("data --->", data);

      if (response.error) {
        console.error(response.error);
        toast.error(response.error);
      } else {
        this.cookies.set("accessToken", response.accessToken, { path: "/" });
        toast.success("Reset Password Success!");
        this.oldPassword = "";
        this.newPassword = "";
        this.confirmNewPassword = "";
        // this.token = response?.accessToken
        // console.log("response =====>",response)
        // console.log("this.token =====>",this.accessToken)
        window.location.pathname = "/company";
      }
    } catch (error) {
      console.error("An error occurred while resetting the password:", error);
    }
  };

  _handleAdminPasswordReset = async (token, userId) => {
    // console.log("userid",userId)
    try {
      const data = {
        password: this.newPassword,
        uId: userId,
      };

      if (this.newPassword === "") {
        toast.error("Please enter a new password.");
        return;
      }

      if (this.newPassword !== this.confirmNewPassword) {
        toast.error("Confirm new Password does not match!");
        return;
      }

      if (!/\d/.test(this.newPassword)) {
        toast.error("New password should include numeric characters.");
        return;
      }

      if (!/[a-zA-Z]/.test(this.newPassword)) {
        toast.error("New password should include alphabetic characters.");
        return;
      }

      const response = await resetAdminPassword(token, data);
      if (response.error) {
        console.error(response.error);
        toast.error(response.error);
      } else if (response.payload && response.payload.message) {
        toast.success(response.payload.message);
        this.newPassword = "";
        this.confirmNewPassword = "";
      }
    } catch (error) {
      console.error("An error occurred while resetting the password:", error);
    }
  };

  _handleUserPasswordReset = async (token, userId) => {
    try {
      const data = {
        old_password: this.oldPassword,
        password: this.newPassword,
        uId: userId,
      };

      //   if (this.newPassword === "") {
      //     toast.error("Please enter a new password.");
      //     return;
      //   }

      if (this.newPassword !== this.confirmNewPassword) {
        toast.error("Confirm new Password does not match!");
        return;
      }

      if (!/\d/.test(this.newPassword)) {
        toast.error("New password should include numeric characters.");
        return;
      }

      if (!/[a-zA-Z]/.test(this.newPassword)) {
        toast.error("New password should include alphabetic characters.");
        return;
      }

      const response = await resetUserPassword(token, data);
      console.log("response --->", response);
      console.log("data --->", data);

      if (response.error) {
        console.error(response.error);
        toast.error(response.error);
      } else if (response.payload && response.payload.message) {
        toast.success(response.payload.message);
        this.oldPassword = "";
        this.newPassword = "";
        this.confirmNewPassword = "";
      }
    } catch (error) {
      console.error("An error occurred while resetting the password:", error);
    }
  };

  _setLinkId = (id) => {
    this.lid = id;
  };

  _fetchInviteLinkInfo = () => {
    fetchInviteLinkInfo(this.lid, (err, response) => {
      if (response) {
        this.inviteLinkInfo = response.response;
      } else {
        toast.error(err);
      }
    });
  };

  _fetchRegister = (history) => {
    let data = {
      username: this.username,
      email: this.email,
      password: this.password,
      confirmPassword: this.confirmPassword,
      companyId: this.inviteLinkInfo.company_id,
      invId: this.lid,
    };

    const err = RegisterFormValidator(data);

    if (err.userNameErr) {
      toast.error(err.userNameErr);
    } else if (err.emailErr) {
      toast.error(err.emailErr);
    } else if (err.passwordErr) {
      toast.error(err.passwordErr);
    } else if (err.confirmPasswordErr) {
      toast.error(err.confirmPasswordErr);
    } else if (err.passwordMatchErr) {
      toast.error(err.passwordMatchErr);
    } else {
      this.show = true;
      console.log(data);
      fetchRegister(data, (err, response) => {
        if (response) {
          if (!response.error) {
            toast.error(response.error);
          } else {
            if (response.message === "Token Expired!") {
              window.location.pathname = "/";
              toast.info(response.message);
            }
            toast.success(response.message);
            // Login.auth = true;
            // const companyName = jwt_decode(response.accessToken).companyName;
            // this.role = jwt_decode(response.accessToken).role;
            // this.cookies.set("accessToken", response.accessToken, { path: "/" });
            // this.cookies.set("company", jwt_decode(response.accessToken).companyId);

            // if (this.role === "driver") {
            //     window.location.pathname = "/m_vehicles";
            // } else {
            //     window.location.pathname = "/company";
            // }
            // window.location.pathname = "/";
            history.push("/");
          }
        } else {
          toast.error(err.toString());
          this.isDisabled = false;
        }
        this.show = false;
      });
    }
  };

  _handleUsernameChange = (value) => {
    this.username = value;
  };

  _handleEmailChange = (value) => {
    this.email = value;
  };

  _handlePasswordChange = (value) => {
    this.password = value;
  };

  _handleOldPasswordChange = (value) => {
    this.oldPassword = value;
  };

  _handleNewPasswordChange = (value) => {
    this.newPassword = value;
  };

  _handleConfirmNewPasswordChange = (value) => {
    this.confirmNewPassword = value;
  };

  _handleConfirmPasswordChange = (value) => {
    this.confirmPassword = value;
  };

  _handleOldPasswordView = () => {
    this.oldPasswordView = !this.oldPasswordView;
  };

  _handleNewPasswordView = () => {
    this.newPasswordView = !this.newPasswordView;
  };

  _handleConfirmNewPasswordView = () => {
    this.confirmNewPasswordView = !this.confirmNewPasswordView;
  };

  _handlePassView = () => {
    this.passView = !this.passView;
  };

  _handleConfirmPassView = () => {
    this.confirmPassView = !this.confirmPassView;
  };

  _handleResetUserPassword = async (userId) => {
    let userData = {
      uId: userId,
      old_password: this.oldPassword,
      password: this.newPassword,
    };

    await resetUserPassword(this.token, userData, (err, data) => {
      if (data.error) {
        toast.error(data?.error);
      } else {
        toast.success(data.payload.message);
        setTimeout(() => {
          localStorage.removeItem("theme");
          this.cookies.remove("accessToken");
          this.cookies.remove("companyName");
          this.cookies.remove("storedTable");

          window.location.pathname = "/";
          fetchLogout();
        }, 2500);
      }
    });
  };
}

export default new Register();
