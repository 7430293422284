import React from 'react'
import { inject, observer } from "mobx-react";
import { CompanySelector } from '../Dashboard/companySelector';
import { baseURL } from '../../network/URL';
import { Colors, invertDark } from '../../Assets/Config/color.config';

export const ManualEntryHistoryViewModal = inject("store")(
    observer((props) => {

        const { theme, manualRowsData } = props;
        // console.log('First row of manualRowsData:', manualRowsData[0]);

        return (
            <div
                className="modal fade"
                id="receiptviewmodal"
                tabIndex="-1"
                aria-labelledby="receiptviewmodal"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content" 
                        style={{ 
                            background: theme === 'dark' ? Colors[theme].background : "",
                            color: invertDark(theme)
                        }}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title d-flex justify-content-center align-items-center" style={{ color: invertDark(theme) }}><span>Invoice No. - {manualRowsData[0]?.invoice_no}</span></h5>
                            <button type="button" className="btn-close" aria-label="Close" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body">
                            <img src={baseURL + manualRowsData[0]?.fuel_recordFilePath} title='fuel receipt'/>
                        </div>
                        {/* <div className="modal-footer">
                            Fuel Receipt
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }))
// export const ManualEntryHistoryViewModal = inject("store")(
//   observer((props) => {
//     const { theme, receiptData } = props;

//     return (
//       <div
//         className="modal fade"
//         id="receiptviewmodal"
//         tabIndex="-1"
//         aria-labelledby="receiptviewmodal"
//         aria-hidden="true"
//       >
//         <div className="modal-dialog modal-md" role="document">
//           <div className="modal-content" 
//             style={{ 
//               background: theme === 'dark' ? Colors[theme].background : "",
//               color: invertDark(theme)
//             }}
//           >
//             <div className="modal-header">
//               <h5 className="modal-title d-flex justify-content-center align-items-center" style={{ color: invertDark(theme) }}>
//                 <span>Invoice No. - {receiptData?.invoice_no}</span>
//               </h5>
//               <button type="button" className="btn-close" aria-label="Close" data-bs-dismiss="modal"></button>
//             </div>
//             <div className="modal-body">
//               <img src={baseURL + receiptData?.fuel_recordFilePath} title='fuel receipt'/>
//             </div>
//           </div>
//         </div>
//       </div>
//     )
//   })
// )


