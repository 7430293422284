import "./App.css";
import Navbar from "./App/navBar";
import SideBar from "./App/sideBar";
import DrawerMenu from "./App/drawer";
import { Switch, Route, Redirect, withRouter, useLocation } from "react-router-dom";
import DashboardContainer from "./Containers/Dashboard/DashboardContainer";
import Tracking from "./Containers/Tracking/TrackingContainer2";
// import { NewTrackingContainer } from "./Containers/Tracking/NewTrackingContainer";
import { NewTrackingContainer } from "./Containers/Tracking2/NewTrackingContainer";
import LoginContainer from "./Containers/Login/LoginContainer";
import { SubscribeContainer } from "./Containers/SSOPages/SubscribePage";
import { Fragment, useEffect, useState } from "react";
import ErrorPage from "./Pages/ErrorPage/errorPage";
import { Cookies } from "react-cookie";
import { inject, observer } from "mobx-react";
import { ToastContainer, toast } from "react-toastify";
import LandingContainer from "./Containers/Landing/LandingContainer";
import TrackAndTraceContainer from "./Containers/TrackAndTrace/TrackAndTraceContainer";
import AssetProfileContainer from "./Containers/AssetProfile/AssetProfileContainer";
import AdminPanelContainer from "./Containers/AdminPanel/AdminPanelContainer";
import ManualEntryContainer from "./Containers/ManualEntry/ManualEntryContainer";
import { CompanyVehiclesContainer } from "./Mobile/CompanyVehicles/CompanyVehiclesContainer";
import "bootstrap";
import MenuBar from "./App/menuBar";
import { FuelRecord } from "./Mobile/FuelRecords/FuelRecordContainer";
import { FuelHistory } from "./Mobile/FuelHistory/FuelHistory";
import { RegisterContainer } from "./Containers/Register/RegisterContainer";
import { DriverRegisterContainer } from "./Containers/Register/DriverRegisterContainer";
import { NewManualEntry } from "./Containers/ManualEntry/NewManualEntryContainer";
import { LightManualEntry } from "./Containers/LightManualEntry/LightManualEntryContainer";
import { NewTrackAndTraceContainer } from "./Containers/TrackAndTrace/NewTrackAndTraceContainer";
import jwt_decode from "jwt-decode";
import {
  LiteDashboard,
  LiteDashboardContainer,
} from "./Containers/LiteDashboard/LiteDashboardContainer";
import { CompanySetUpContainer } from "./Containers/CompanySetUp/CompanySetUpContainer";
import { DataImport } from "./Containers/DataImport/DataImportContainer";
import { CustomerSupport } from "./Containers/CustomerSupport/CustomerSupportContainer";
import { VehicleProfilesTable } from "./Components/DataImport/VehicleProfilesTable";
import { ViewForManualEntry } from "./Components/DataImport/ViewForManualEntry";
import io from "socket.io-client";
import Logs from "./Components/Logs/Logs";
import { useTranslation } from "react-i18next";
import { baseURL } from "./network/URL";
import { toJS } from "mobx";
import LogsToast from "./Components/Notification/LogsToast";
import UserGuideContainer from "./Containers/UserGuideContainer/UserGuideContainer";
import CertificateContainer from "./Containers/Certificate/CertificateContainer";
import PasswordResetContainer from "./Containers/PasswordReset/PasswordResetContainer";
import { NewContainer } from "./Containers/What'sNew/What'sNewContainer";

function App(props) {
  const [width, setWidth] = useState(window.innerWidth);
  const WindowSize = (e) => {
    setWidth(e.target.innerWidth);
  };
  window.addEventListener("resize", WindowSize);

  // const pathName = window.location.pathname;
  const location = useLocation();
  const pathName = location.pathname;
  const isLoginPage = pathName === "/";
  const { auth, setAuth, onChangeTheme, theme } = props.store.login;
  // const {} =props.store.common;
  const { _handleGetAllRelatedCompany, relatedCompanies } =
    props.store.adminPanel;
  const { setUser, user, receivedUserAction } = props.store.dashboard;

  const { isAds, role } = props.store.common;
  const cookies = new Cookies();
  const token = cookies.get("accessToken");
  const planData = token && jwt_decode(token).planData;
  const isFirstTimeLogin = token && jwt_decode(token).isFirstTimeLogin
  // console.log("isFirstTimeLogin=======>",isFirstTimeLogin)
  const showTable = props;
  const companyName = cookies.get("companyName");
  const companyId = cookies.get("company");
  const t = useTranslation("dashboard");
  const [userAction, setUserAction] = useState({});
  const [socket, setSocket] = useState(null);
  const [position, setPosition] = useState("bottom-right");
  const [news, setNews] = useState([]);

  useEffect(() => {
    fetch("/what'sNew/news.json")
      .then((response) => response.json())
      .then((data) => setNews(data));
  }, []);

  useEffect(() => {
    // _handleGetAllRelatedCompany()
  }, []);

  useEffect(() => {
    window.addEventListener("adsThemeChanged", (e) => {
      onChangeTheme(e.detail.theme);
    });

    return () => {
      window.removeEventListener("adsThemeChanged", () => {}, false);
      // socket.disconnect();
    };
  }, []);

  useEffect(() => {
    const token = cookies.get("accessToken");
    const socketInstance = io.connect(baseURL, {
      path: "/update",
      auth: { token: token },
    });

    socketInstance.on("connect", () => {
      console.log("Connected to socket.io");
      socketInstance.emit("join_company_room", companyId);
    });

    socketInstance.on("userActivity", (receivedUserAction) => {
      setUserAction(receivedUserAction);

      // setUser(receivedUserAction);
    });

    socketInstance.on("disconnect", () => {
      console.log("Socket disconnected");
    });

    setSocket(socketInstance);

    return () => {
      socketInstance.disconnect();
    };
  }, [token]);

  //   useEffect(()=>{
  // const socket =  io.connect(baseURL,{path:"/update", auth:{token:token}})
  //     // console.log(socket)

  //     socket?.on('connect', () => {
  //       // console.log('Connected to socket.io');
  //       socket?.emit("join_company_room", companyId);
  //     });

  //     socket?.on('userActivity', (receivedUserAction) => {
  //       setUserAction(receivedUserAction);
  //       setUser(receivedUserAction);

  //     });

  //     // socket.on('userActivity', (user) => {
  //     //   // toast.info(user.action_detail)
  //     //   setUser(user);
  //     //   console.log(user)
  //     // });

  //     socket?.on('disconnect', () => {
  //       console.log('Socket disconnected');
  //     });
  //   },[token,socket,receivedUserAction])

  useEffect(() => {
    token ? setAuth(true) : setAuth(false);
    var x = document.getElementsByTagName("BODY")[0];
    const localTheme = localStorage.getItem("theme");
    if (!localTheme) {
      localStorage.setItem("theme", "light");
    } else if (pathName === "/" && localTheme === "light") {
      localStorage.setItem("theme", "light");
    }

    !theme || theme === "dark"
      ? (x.style.backgroundImage = "linear-gradient(-100deg,#101841,#202b60)")
      : (x.style.background = "white");
  }, [theme]); // eslint-disable-line react-hooks/exhaustive-deps

  // Feedback
  // useEffect(() => {
  //   if( pathName !== '/') {
  //     const script = document.createElement("script");

  //     script.src = "https://desk.zoho.com/portal/api/feedbackwidget/765725000000651007?orgId=785751557&displayType=popout";
  //     script.async = true;

  //     document.body.appendChild(script);
  //   }
  // }, [])

  const components = [
    {
      path: "/m_vehicles",
      access: planData?.driver_vehicle_access,
      component: <CompanyVehiclesContainer theme={theme} />,
    },
    {
      path: "/m_fuel_record",
      access: planData?.driver_fuel_record_access,
      component: <FuelRecord theme={theme} />,
    },
    {
      path: "/m_fuel_history",
      access: planData?.driver_fuel_history_access,
      component: <FuelHistory theme={theme} />,
    },
    {
      path: "/admin_panel",
      component: <AdminPanelContainer theme={theme} />,
    },
    {
      path: "/logs",
      component: <Logs theme={theme} />,
    },
    {
      path: "/tracking",
      access: planData?.unit_level_access,
      component: <Tracking theme={theme} />,
    },
    {
      path: "/new-tracking",
      access: planData?.unit_level_access,
      component: <NewTrackingContainer theme={theme} width={width} />,
    },
    {
      path: "/organisation-level-carbon-emission-dashboard",
      access: planData?.plan_name === "CO2XDriver" ? 0 : 1,
      component: <DashboardContainer theme={theme} />,
    },
    {
      path: "/carbon_emission_reduction_certificate",
      access: planData?.plan_name === "CO2XDriver" ? 0 : 1,
      component: <CertificateContainer theme={theme} />,
    },
    {
      path: "/track_and_trace",
      access: planData?.enterprise_report_access,
      component: <NewTrackAndTraceContainer theme={theme} width={width} />,
    },
    {
      path: "/asset_profile_management",
      access: planData?.unit_access,
      component: <AssetProfileContainer theme={theme} />,
    },
    {
      path: "/company", // remove for everkumo.com
      access: 1,
      component: <LandingContainer theme={theme} t={t} />,
    },
    {
      path: "/video_guide",
      component: <UserGuideContainer theme={theme} t={t} />,
    },
    {
      path: "/manual_entry",
      access: planData?.data_entry_access,
      component: <ManualEntryContainer theme={theme} />,
    },
    {
      path: "/new-manual-entry",
      access: planData?.data_entry_access,
      component: <NewManualEntry theme={theme} />,
    },
    {
      path: "/lite-manual-entry",
      access: planData?.co2xLite_report_and_dataEntry_access,
      component: <LightManualEntry theme={theme} />,
    },
    {
      path: "/lite-dashboard",
      access: planData?.co2xLite_overview_access,
      component: <LiteDashboardContainer theme={theme} />,
    },
    {
      path: "/company-setup",
      access: 1,
      component: <CompanySetUpContainer theme={theme} />,
    },
    {
      path: "/data_import",
      access: planData?.data_import_access,
      component: <DataImport theme={theme} />,
    },
    {
      path: "/customer_support",
      access: 1,
      component: <CustomerSupport theme={theme} />,
    },
    {
      path: "/view_imports",
      access: 1,
      component: (
        <ViewForManualEntry
          role={role}
          companyName={companyName}
          showTable={showTable}
          theme={theme}
        />
      ),
    },
  ];
  return (
    <Fragment>
      {pathName !== "/" &&
      pathName !== "/co2/" &&
      !pathName.includes("/register") &&
      !pathName.includes("/password-reset") &&
      !pathName.includes("/subscribe") &&
      !pathName.includes("/what's_new") ? (
        isAds ? (
          <MenuBar theme={theme} />
        ) : (
          <Fragment>
            <Navbar
              width={width}
              onChangeTheme={onChangeTheme}
              theme={theme}
              receivedUserAction={receivedUserAction}
              socket={socket}
              setUserAction={setUserAction}
            />
            {pathName !== "/company" &&
              pathName !== "/m_vehicles" &&
              pathName !== "/m_fuel_record" &&
              pathName !== "/m_fuel_history" && <SideBar theme={theme} />}
            {pathName !== "/company" &&
              pathName !== "/m_vehicles" &&
              pathName !== "/m_fuel_record" &&
              pathName !== "/m_fuel_history" && <DrawerMenu theme={theme} />}
          </Fragment>
        )
      ) : null}

      {/* <div
        style={{
          paddingTop: !isAds && "25px",
          marginLeft:
            window.location.pathname !== "/company" &&
            (isAds ? 20 : width > 701 ? 70 : 10),
          marginRight: 10,
        }}
      > */}
      <div
        style={
          isLoginPage
            ? {}
            : {
                paddingTop: !isAds && "25px",
                marginLeft:
                  pathName !== "/company" &&
                  (isAds ? 20 : width > 701 ? 70 : 10),
                marginRight: 10,
              }
        }
      >

        <Switch>
          <Route exact path="/" render={() => <LoginContainer />} />
          <Route
            exact
            path="/password-reset/:userId"
            render={(props) => {
              const { userId } = props.match.params;
              console.log("userId:", userId);
              return (
                <Redirect
                  to={{ pathname: "/password-reset", state: { userId } }}
                />
              );
            }}
          />

          <Route
            exact
            path="/password-reset"
            render={(props) => {
              const { userId } = props.location.state || {};
              console.log("userId:", userId);
              return <PasswordResetContainer user={userId} />;
            }}
          />
          <Route exact path="/register" render={() => <RegisterContainer />} />
          <Route
            exact
            path="/register/:id"
            render={() => <DriverRegisterContainer />}
          />
          <Route
            exact
            path="/subscribe"
            render={() => <SubscribeContainer />}
          />

          {components.map((v, k) => (
            <Route
              auth={auth}
              path={v.path}
              key={k}
              render={() =>
                (v.access === 1 || planData?.plan_name === "CO2XEnterprise") &&
                auth ? (
                  v.component
                ) : (
                  <Redirect to="/" from="/" />
                )
              }
            />
          ))}
          <Route
            exact
            path="/what's_new"
            render={(props) => {
              const newsId = props.match.params.id;
              const newsItem = news.find((item) => item.id === newsId);
              return <NewContainer theme={theme} newsItem={newsItem} t={t} />;
            }}
          />
          <Route
            path="*"
            render={() =>
              auth ? (
                <ErrorPage ErrorInfo={"Page Not Found!"} />
              ) : (
                <Redirect to="/" from="/" />
              )
            }
          />
        </Switch>
      </div>

      <LogsToast userAction={userAction} position="bottom-right" />

      {/* <LogsToast user={user}/> */}
      <ToastContainer />
    </Fragment>
  );
}

export default inject("store")(withRouter(observer(App)));
