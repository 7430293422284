/* eslint-disable react/jsx-pascal-case */
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { My_Button, My_Input, My_upload_image, Selector } from "../../Tools";
import { useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";
import Store from "../../Store";
import React, { useEffect, useState } from "react";
import allCountry from "../../Assets/countries.json";
import { Checkbox } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { typeOptions } from "./TypeOptions";
import { UploadImage } from "./UploadImage";
import { useTranslation } from "react-i18next";

export const CreateCompanyModalNew = inject("store")(
  observer((props) => {
    const { t } = useTranslation("admin-panel");
    const { theme } = props;
    const {
      level,
      _handleInputChangeNew,
      accountData,
      companyInfo,
      _handleCompanyInputChange,
      photo,
      _handleFormClear,
      _handleCreateCompanyNew,
      _handleCheckBox,
    } = props.store.adminPanel;

    const [phoneNumber, setPhoneNumber] = useState("");

    const CountryOptions = allCountry?.map((country) => ({
      value: country.name,
      label: country.name,
      code: country.code.toLowerCase(),
    }));

    const levels = ["1", "2", "3", "4", "5"];

    const higherLevels = levels.filter((v) => v > level);

    const levelOptions = higherLevels.map((d, i) => ({
      value: d,
      label: d,
    }));

    const companySizeOptions = [
      {
        value: "1 - 9",
        label: "1 - 9",
      },
      {
        value: "10 - 49",
        label: "10 - 49",
      },
      {
        value: "50 - 249",
        label: "50 - 249",
      },
      {
        value: "250 - 499",
        label: "250 - 499",
      },
    ];

    const companyRevenueOptions = [
      {
        value: "Below 1 million",
        label: "Below 1 million",
      },
      {
        value: "1 - 3 million",
        label: "1 - 3 million",
      },
      {
        value: "3 - 5 million",
        label: "3 - 5 million",
      },
      {
        value: "Above 5 million",
        label: "Above 5 million",
      },
    ];

    return (
      <div
        className="modal fade"
        id="createcompanymodalnew"
        tabIndex="-1"
        aria-labelledby="createcompanymodalnew"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl rounded">
          <div
            className="modal-content"
            style={{
              background: Colors[theme]?.background,
              color: invertDark(theme),
            }}
          >
            <div
              className="modal-header"
              style={{
                background: Colors[theme]?.background,
                color: invertDark(theme),
                border: "none",
                padding: 6,
              }}
            >
              <span
                style={{
                  fontSize: 24,
                  color: theme === "dark" ? "#fff" : Colors[theme].color,
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  marginLeft: 2,
                }}
              >
                {t("Create New Company")}
              </span>

              <span
                data-bs-dismiss="modal"
                onClick={() => _handleFormClear()}
                style={{ cursor: "pointer", marginRight: 10 }}
              >
                <i className="bi bi-x-lg" />
              </span>
            </div>

            <div
              className="modal-body"
              style={{ background: Colors[theme]?.background }}
            >
              <div
                className="d-flex align-items-center justify-content-between"
                style={{
                  border: theme === "dark" ? "" : "1px solid black",
                  height: "550px",
                }}
              >
                <div
                  className="d-flex flex-column justify-content-between"
                  style={{
                    background: theme === "dark" ? "#192353" : "#fff",
                    borderBottomRightRadius: theme === "dark" ? 5 : "",
                    borderBottomLeftRadius: 5,
                    height: "100%",
                    width: "49%",
                    borderRight: theme === "dark" ? "" : "1px solid black",
                    overflow: "scroll",
                  }}
                >
                  <div className="p-2">{t("Company Information")}</div>
                  <div className="w-100 d-flex justify-content-center">
                    <div
                      className="col-4 py-3 px-3"
                      style={{ height: "230px" }}
                    >
                      <UploadImage photo={photo} />
                    </div>
                  </div>
                  <div className="py-2 pe-5 ps-3">
                    <div className="d-flex align-items-center pb-3">
                      <span className="col-6">
                        <span style={{ color: "#ffc107" }}>*</span> {t("Name")}
                      </span>
                      <span className="col-6">
                        <My_Input
                          id={"companyName"}
                          value={companyInfo.name}
                          placeHolder={"Enter company name..."}
                          onChange={(e) =>
                            _handleCompanyInputChange({ name: e.target.value })
                          }
                        />
                      </span>
                    </div>

                    <div className="d-flex align-items-center pb-3">
                      <span className="col-6">
                        <span style={{ color: "#ffc107" }}>*</span>{" "}
                        {t("Country")}
                      </span>
                      <span className="col-6">
                        <Selector
                          options={CountryOptions}
                          menuListColor="black"
                          _handleSelect={(e) => {
                            _handleCompanyInputChange({ country: e.value });
                          }}
                        />
                      </span>
                    </div>

                    <div className="d-flex align-items-center pb-3">
                      <span className="col-6">
                        <span style={{ color: "#ffc107" }}>*</span>{" "}
                        {t("Incorporation")}
                      </span>
                      <span className="col-6">
                        <Selector
                          options={CountryOptions}
                          menuListColor="black"
                          _handleSelect={(e) => {
                            _handleCompanyInputChange({
                              company_incorporation: e.value,
                            });
                          }}
                        />
                      </span>
                    </div>
                    <div className="d-flex align-items-center pb-3">
                      <span className="col-6">
                        <span style={{ color: "#ffc107" }}>*</span>{" "}
                        {t("Industry Sector")}
                      </span>
                      <span className="col-6">
                        <Selector
                          options={typeOptions}
                          menuListColor="black"
                          _handleSelect={(e) => {
                            _handleCompanyInputChange({ sector: e.value });
                          }}
                        />
                      </span>
                    </div>
                    <div className="d-flex align-items-center pb-3">
                      <span className="col-6">
                        <span style={{ color: "#ffc107" }}>*</span>{" "}
                        {t("Company Level")}
                      </span>
                      <span className="col-6">
                        <Selector
                          options={levelOptions}
                          menuListColor="black"
                          _handleSelect={(e) => {
                            _handleCompanyInputChange({ level: e.value });
                          }}
                        />
                      </span>
                    </div>
                    <div className="d-flex align-items-center pb-3">
                      <span className="col-6">{t("Ascent Account ID")}</span>
                      <span className="col-6">
                        <My_Input
                          id={"companyName"}
                          value={companyInfo.ascentAccountId}
                          placeHolder={"Enter ascent account id..."}
                          onChange={(e) =>
                            _handleCompanyInputChange({
                              ascentAccountId: e.target.value,
                            })
                          }
                        />
                      </span>
                    </div>
                    {/* <div className='d-flex align-items-center pb-3'>
                                            <span className='col-6'>
                                                Company Registration Number (Company in UEN context)
                                            </span>
                                            <span className='col-6'>
                                                <My_Input
                                                    // type={'number'}
                                                    id={'compnayRegNumber'}
                                                    value={companyInfo.companyRegNumber}
                                                    placeHolder={"Enter company registration number..."}
                                                    onChange={(e) => _handleCompanyInputChange({ companyRegNumber: e.target.value })}
                                                />
                                            </span>
                                        </div> */}

                    <div className="d-flex align-items-center pb-3">
                      <span className="col-6">
                        <span style={{ color: "#ffc107" }}>*</span> Company
                        Address
                      </span>
                      <span className="col-6">
                        <My_Input
                          // type={'number'}
                          id={"companyAddress"}
                          value={companyInfo.address}
                          placeHolder={"Enter company address..."}
                          onChange={(e) =>
                            _handleCompanyInputChange({
                              address: e.target.value,
                            })
                          }
                        />
                      </span>
                    </div>

                    <div className="d-flex align-items-center pb-3">
                      <span className="col-6">
                        <span style={{ color: "#ffc107" }}>*</span>{" "}
                        {t("Company Website")}
                      </span>
                      <span className="col-6">
                        <My_Input
                          // type={'number'}
                          id={"companyWebsite"}
                          value={companyInfo.website}
                          placeHolder={"Enter company website..."}
                          onChange={(e) =>
                            _handleCompanyInputChange({
                              website: e.target.value,
                            })
                          }
                        />
                      </span>
                    </div>

                    <div className="d-flex align-items-center pb-3">
                      <span className="col-6">{t("Company Phone Number")}</span>
                      <span
                        className="col-6"
                        onChange={() =>
                          _handleCompanyInputChange({ phone: phoneNumber })
                        }
                      >
                        {/* <My_Input
                                                    // type={'number'}
                                                    value={companyInfo.companyPhone}
                                                    placeHolder={"Enter company registration number..."}
                                                    onChange={(e) => _handleCompanyInputChange({ companyPhone: e.target.value })}
                                                /> */}
                        <PhoneInput
                          international
                          country={"sg"}
                          value={phoneNumber}
                          onChange={setPhoneNumber}
                          inputStyle={{
                            background: theme === "dark" && Colors.darkBlue,
                            color: invertDark(theme),
                            width: "100%",
                            paddingTop: 10,
                            paddingBottom: 10,
                            fontSize: 14,

                            boxShadow: "none",
                            shapeOutline: "none",
                            outline: "none",
                            border: `1px solid ${Colors.secondaryColor}`,
                            borderRadius: 5,
                          }}
                          buttonStyle={{
                            background: theme === "dark" && Colors.darkBlue,
                            color: invertDark(theme),
                          }}
                          dropdownStyle={{
                            width: "250px",
                            background: theme === "dark" && Colors.darkBlue,
                            color: invertDark(theme),
                          }}
                        />
                      </span>
                    </div>

                    <div className="d-flex align-items-center pb-3">
                      <span className="col-6">
                        {t(
                          "Company Registration Number (Company in UEN context)"
                        )}
                      </span>
                      <span className="col-6">
                        <My_Input
                          // type={'number'}
                          id={"registration_number"}
                          value={companyInfo.registration_number}
                          placeHolder={"Enter company registration number..."}
                          onChange={(e) =>
                            _handleCompanyInputChange({
                              registration_number: e.target.value,
                            })
                          }
                        />
                      </span>
                    </div>

                    <div className="d-flex align-items-center pb-3">
                      <span className="col-6">{t("Company LEI Number")}</span>
                      <span className="col-6">
                        <My_Input
                          // type={'number'}
                          id={"companyLeiNumber"}
                          value={companyInfo.leiNo}
                          placeHolder={"Enter company LEI number..."}
                          onChange={(e) =>
                            _handleCompanyInputChange({ leiNo: e.target.value })
                          }
                        />
                      </span>
                    </div>

                    <div className="d-flex align-items-center pb-3">
                      <span className="col-6">
                        <span style={{ color: "#ffc107" }}>*</span>{" "}
                        {t("Company Size")}
                      </span>
                      <span className="col-6">
                        <Selector
                          options={companySizeOptions}
                          menuListColor="black"
                          _handleSelect={(e) =>
                            _handleCompanyInputChange({ company_size: e.value })
                          }
                        />
                      </span>
                    </div>

                    <div className="d-flex align-items-center pb-3">
                      <span className="col-6">
                        <span style={{ color: "#ffc107" }}>*</span>{" "}
                        {t("Company Revenue")}
                      </span>
                      <span className="col-6">
                        <Selector
                          options={companyRevenueOptions}
                          menuListColor="black"
                          _handleSelect={(e) =>
                            _handleCompanyInputChange({ revenue: e.value })
                          }
                        />
                      </span>
                    </div>

                    <div className="d-flex align-items-center pb-3">
                      <span className="col-6">
                        <span style={{ color: "#ffc107" }}>*</span>{" "}
                        {t("UN Global Compact SignUp")}
                      </span>
                      <span className="col-6">
                        <span className="d-flex align-items-center">
                          <span
                            className="d-flex align-items-center pe-3"
                            onClick={() =>
                              _handleCheckBox({ isCompact_signup: "YES" })
                            }
                          >
                            <Checkbox
                              style={{
                                color: Colors.activeColor,
                                padding: "0px",
                              }}
                              checked={
                                companyInfo?.isCompact_signup === "YES"
                                  ? true
                                  : false
                              }
                            />
                            <span
                              className="ms-1"
                              style={{ cursor: "pointer" }}
                            >
                              {t("YES")}
                            </span>
                          </span>
                          <span
                            className="d-flex align-items-center"
                            onClick={() =>
                              _handleCheckBox({ isCompact_signup: "NO" })
                            }
                          >
                            <Checkbox
                              style={{
                                color: Colors.activeColor,
                                padding: "0px",
                              }}
                              checked={
                                companyInfo?.isCompact_signup === "NO"
                                  ? true
                                  : false
                              }
                            />
                            <span
                              className="ms-1"
                              style={{ cursor: "pointer" }}
                            >
                              {t("NO")}
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>

                    <div className="d-flex align-items-center pb-3">
                      <span className="col-6">
                        <span style={{ color: "#ffc107" }}>*</span>{" "}
                        {t("Environmental Policy")}
                      </span>
                      <span className="col-6">
                        <span className="d-flex align-items-center">
                          <span
                            className="d-flex align-items-center pe-3"
                            onClick={() =>
                              _handleCheckBox({ isWrittenEnv_policy: "YES" })
                            }
                          >
                            <Checkbox
                              style={{
                                color: Colors.activeColor,
                                padding: "0px",
                              }}
                              checked={
                                companyInfo?.isWrittenEnv_policy === "YES"
                                  ? true
                                  : false
                              }
                            />
                            <span
                              className="ms-1"
                              style={{ cursor: "pointer" }}
                            >
                              {t("YES")}
                            </span>
                          </span>
                          <span
                            className="d-flex align-items-center"
                            onClick={() =>
                              _handleCheckBox({ isWrittenEnv_policy: "NO" })
                            }
                          >
                            <Checkbox
                              style={{
                                color: Colors.activeColor,
                                padding: "0px",
                              }}
                              checked={
                                companyInfo?.isWrittenEnv_policy === "NO"
                                  ? true
                                  : false
                              }
                            />
                            <span
                              className="ms-1"
                              style={{ cursor: "pointer" }}
                            >
                              {t("NO")}
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>

                    <div
                      className="d-flex align-items-center pb-3"
                      onClick={(e) =>
                        _handleCheckBox({
                          isDeclarationOfLegalBusiness: e.target.checked,
                        })
                      }
                    >
                      <span style={{ cursor: "pointer" }} className="me-3">
                        <Checkbox
                          style={{ color: Colors.activeColor, padding: "0px" }}
                          checked={
                            companyInfo?.isDeclarationOfLegalBusiness
                              ? true
                              : false
                          }
                        />
                      </span>
                      <span style={{ cursor: "pointer" }}>
                        <span style={{ color: "#ffc107" }}>*</span>{" "}
                        {t("Company declares it is a legal business.")}
                      </span>
                    </div>

                    {/* <div className='d-flex align-items-center pb-3'>
                                            <span className='col-6'>
                                                Date of Account Creation
                                            </span>
                                            <span className='col-6'>
                                                <My_DatePicker height={'43px'} selected={companyInfo.registerDate} onChange={(e) => _handleChangeRegisterDate(e)} />
                                            </span>
                                        </div>

                                        <div className='d-flex align-items-center pb-3'>
                                            <span className='col-6'>
                                                Subscription Expiry Date
                                            </span>
                                            <span className='col-6'>
                                                <My_DatePicker height={'43px'} selected={companyInfo.expireDate} onChange={(e) => _handleChangeExpireDate(e)} />
                                            </span>
                                        </div> */}
                  </div>
                </div>

                <div
                  className=""
                  style={{
                    background: theme === "dark" ? "#192353" : "#fff",
                    borderBottomRightRadius: 5,
                    borderBottomLeftRadius: 5,
                    height: "100%",
                    width: "49%",
                  }}
                >
                  <div className="p-2">{t("Create Company Admin")}</div>

                  <div className="py-5 px-5">
                    <div className="d-flex align-items-center pb-3">
                      <span className="col-4">
                        <span style={{ color: "#ffc107" }}>*</span> {t("Email")}
                      </span>
                      <span className="col-8">
                        <My_Input
                          id={"email"}
                          required={false}
                          value={accountData.email}
                          height="38px"
                          placeHolder={"Enter email"}
                          onChange={(e) =>
                            _handleInputChangeNew({ email: e.target.value })
                          }
                        />
                      </span>
                    </div>
                    <div className="d-flex align-items-center pb-3">
                      <span className="col-4">
                        <span style={{ color: "#ffc107" }}>*</span>{" "}
                        {t("Designation")}
                      </span>
                      <span className="col-8">
                        <My_Input
                          required={false}
                          id={"designatoin"}
                          value={accountData.designation}
                          height="38px"
                          placeHolder={"Enter job title and designation"}
                          onChange={(e) =>
                            _handleInputChangeNew({
                              designation: e.target.value,
                            })
                          }
                        />
                      </span>
                    </div>
                    <div className="d-flex align-items-center pb-3">
                      <span className="col-4">
                        <span style={{ color: "#ffc107" }}>*</span>{" "}
                        {t("First Name")}
                      </span>
                      <span className="col-8">
                        <My_Input
                          required={false}
                          value={accountData.first_name}
                          id={"firstName"}
                          // disabled={editView === true ? true : ''}
                          type="text"
                          height="38px"
                          placeHolder={"Enter first name..."}
                          onChange={(e) =>
                            _handleInputChangeNew({
                              first_name: e.target.value,
                            })
                          }
                        />
                      </span>
                    </div>
                    <div className="d-flex align-items-center pb-3">
                      <span className="col-4">
                        <span style={{ color: "#ffc107" }}>*</span>{" "}
                        {t("Last Name")}
                      </span>
                      <span className="col-8">
                        <My_Input
                          required={false}
                          id={"lastName"}
                          value={accountData.last_name}
                          type="text"
                          height="38px"
                          placeHolder={"Enter last name..."}
                          onChange={(e) =>
                            _handleInputChangeNew({ last_name: e.target.value })
                          }
                        />
                      </span>
                    </div>

                    <div className="d-flex align-items-center pb-3">
                      <span className="col-4">
                        <span style={{ color: "#ffc107" }}>*</span>{" "}
                        {t("Password")}
                      </span>
                      <span className="col-8">
                        <My_Input
                          required={false}
                          value={accountData.password}
                          id={"password"}
                          // disabled={editView === true ? true : ''}
                          type={props.store.login.visible ? "text" : "password"}
                          height="38px"
                          placeHolder={"Enter password..."}
                          onChange={(e) =>
                            _handleInputChangeNew({ password: e.target.value })
                          }
                        />
                        <span
                          style={{
                            float: "right",
                            position: "relative",
                            marginTop: "-52px",
                            marginBottom: "-10px",
                            fontSize: "18px",
                            marginRight: "8px",
                            cursor: "pointer",
                          }}
                          onClick={() => props.store.login._handleView()}
                        >
                          <i
                            className={`fa fa-eye${
                              props.store.login.visible ? "-slash" : ""
                            } py-4 text-secondary`}
                          />
                        </span>
                      </span>
                    </div>
                    <div className="d-flex align-items-center pb-3">
                      <span className="col-4">
                        <span style={{ color: "#ffc107" }}>*</span>{" "}
                        {t("Confirm Password")}
                      </span>
                      <span className="col-8">
                        <My_Input
                          required={false}
                          id={"confirmPassword"}
                          value={accountData.confirmPassword}
                          type={props.store.login.visible ? "text" : "password"}
                          height="38px"
                          placeHolder={"Confirm password..."}
                          onChange={(e) =>
                            _handleInputChangeNew({
                              confirmPassword: e.target.value,
                            })
                          }
                        />
                        <span
                          style={{
                            float: "right",
                            position: "relative",
                            marginTop: "-52px",
                            marginBottom: "-10px",
                            fontSize: "18px",
                            marginRight: "8px",
                            cursor: "pointer",
                          }}
                          onClick={() => props.store.login._handleView()}
                        >
                          <i
                            className={`fa fa-eye${
                              props.store.login.visible ? "-slash" : ""
                            } py-4 text-secondary`}
                          />
                        </span>
                      </span>
                    </div>

                    {/* <div className='d-flex align-items-center pb-3'>
                                        <span className='col-4'>
                                            Assign Company
                                        </span>
                                        <span className='col-8'>
                                            <Selector options={CompanyOptions}  menuListColor={Colors.darkBlue} _handleSelect={(e) => _handleInputChange({ companyId: e.value })} />
                                        </span>
                                    </div> */}

                    {/* {editView === true ? <div className="mt-3 float-end" >
                                        <span style={{ cursor: 'pointer' }}>
                                            <My_Button
                                                customColor={theme === 'dark' ? '#0064FB' : '#04c600'} customHoverColor={theme === 'dark' ? '' : '#05e700'}
                                                text='Update'
                                                onClick={() => _handleUpdateUser(userId)}

                                                style={{ minWidth: 100 }}
                                                customAdd={<i className="bi bi-plus-square-dotted" style={{ marginLeft: "0.5em" }}></i>}
                                            />
                                        </span>

                                    </div> : */}
                  </div>
                </div>
              </div>

              <div className="float-end py-3">
                <My_Button
                  onClick={() => {
                    _handleCreateCompanyNew();
                    // _handleFormClear()
                  }}
                  customColor={theme === "dark" ? "#0064FB" : "#04c600"}
                  customHoverColor={theme === "dark" ? "" : "#05e700"}
                  className="px-3 "
                  text={t("Create")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  })
);

// const assigncompanyoption = [
//     {value: 'KumoSolution', label: 'Kumo Solution'},
//     {value: 'Evercomm', label: 'Evercomm'},
//     {value: 'ABCcompany', label: 'ABC Company'}
// ]
