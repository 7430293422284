import * as API from "./URL";

export const fetchDriverLst = async (token, companyId, callback) => {
  return fetch(`${API.driverListAPI}?companyId=${companyId}`, {
    method: "GET",
    headers: {
      //"Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const fetchSendInviteLink = async (
  token,
  email,
  inviteLink,
  callback
) => {
  return fetch(`${API.inviteLinkSendApi}?email=${email}&link=${inviteLink}`, {
    method: "GET",
    headers: {
      //"Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const fetchInviteLink = async (token, companyId, userId, callback) => {
  return fetch(`${API.inviteLinkAPI}?companyId=${companyId}&userId=${userId}`, {
    method: "GET",
    headers: {
      //"Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const fetchUpdateCompanyLogo = async (
  token,
  companyId,
  data,
  callback
) => {
  return fetch(`${API.getCompanyv1}/uploadCompanyLogo/${companyId}`, {
    method: "POST",
    headers: {
      //"Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: data,
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const fetchUpdateCompanyAscentAccId = async (
  token,
  companyId,
  acc,
  callback
) => {
  return fetch(
    `${API.updateAccIdAPI}?companyId=` + companyId + "&ascentAccId=" + acc,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "x-access-token": token,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const fetchUpdateCompany = async (token, data, callback) => {
  return fetch(`${API.UpdateCompanyAPI}/`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const fetchAllUser = async (token, companyId, callback) => {
  return fetch(`${API.getAllUserListAPI}?companyId=${companyId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const createContract = async (token, data, callback) => {
  return fetch(API.createContractAPI, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const updateUser = async (token, data, callback) => {
  return fetch(API.updateUserAPI, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const deleteUser = async (token, userId, callback) => {
  return fetch(`${API.deleteUserAPI}?id=${userId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const getUserPlan = async (token, callback) => {
  return fetch(API.getUserPlanAPI, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const getUserStatus = async (token, callback) => {
  return fetch(API.getUserStatusAPI, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const editUser = async (token, userId, data, callback) => {
  return fetch(`${API.updateUserListAPI}/${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const editRemiderUser = async (token, userId, data, callback) => {
  return fetch(`${API.updateReminderAPI}/${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const leaveUserUnit = async (token, userId, callback) => {
  return fetch(`${API.userUnit}?userId=${userId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const updatePlan = async (token, userId, planId, callback) => {
  console.log(planId);
  return fetch(`${API.updatePlanAPI}?userId=${userId}&userPlanId=${planId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const updateUserStatus = async (
  token,
  userId,
  userStatusId,
  callback
) => {
  return fetch(
    `${API.updateUserStatusAPI}?userId=${userId}&status=${userStatusId}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "x-access-token": token,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const getReports = async (token, param, callback) => {
  if (param) {
    if (param.userId) {
      return fetch(`${API.getCo2xLiteDataNew}?userId=${param.userId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          "x-access-token": token,
        },
      })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err, null));
    } else if (param.companyId) {
      return fetch(`${API.getCo2xLiteDataNew}?companyId=${param.companyId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          "x-access-token": token,
        },
      })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err, null));
    }
  } else {
    return fetch(`${API.getCo2xLiteDataNew}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "x-access-token": token,
      },
    })
      .then((res) => res.json())
      .then((data) => callback(null, data))
      .catch((err) => callback(err, null));
  }
};

export const changeStatus = async (token, id, status, callback) => {
  return fetch(`${API.changeStatus}?id=${id}&status=${status}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const createCompany = async (token, data, callback) => {
  return fetch(API.createCompanyAPI, {
    method: "POST",
    headers: {
      // "Content-Type": "multipart/form-data ",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: data,
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const createCompanyNew = async (token, data, callback) => {
  return fetch(API.createCompanyNewAPI, {
    method: "POST",
    headers: {
      // "Content-Type": "multipart/form-data ",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: data,
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const updateCompany = async (token, data, callback) => {
  return fetch(API.NewUpdateCompanyAPI, {
    method: "PUT",
    headers: {
      // "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: data,
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const getChildCompanies = async (token, companyId, callback) => {
  return fetch(`${API.getChildCompanies}?companyId=${companyId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const getAllSameLevelCompanies = async (token, companyId, callback) => {
  return fetch(`${API.getAllSameLevelCompanies}?companyId=${companyId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const deleteCompanyInfo = async (token, companyId, callback) => {
  return fetch(`${API.deleteCompanyInfo}?companyId=${companyId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const registerCompany = async (token, data, callback) => {
  return fetch(API.registerCompanyAPI, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const getActivityLogs = async (
  companyId,
  start_date,
  end_date,
  token,
  callback
) => {
  return fetch(
    `${API.getActivityLogsAPI}?companyId=${companyId}&start_date=${start_date}&end_date=${end_date}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "x-access-token": token,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const getLogIssues = async (
  start_date,
  end_date,
  companyId,
  token,
  callback
) => {
  return fetch(
    `${API.getLogIssuesAPI}?start_date=${start_date}&end_date=${end_date}&companyId=${companyId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "x-access-token": token,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

//logsNotification
export const getIssuesNoti = async (companyId, token, callback) => {
  return fetch(`${API.getIssuesNotiApi}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

//logsNotification
export const getLogsNoti = async (companyId, token, callback) => {
  return fetch(`${API.getLogsNotiApi}?companyId=${companyId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

//issue detail
export const getIssueDetail = async (issueId, token, callback) => {
  return fetch(`${API.getIssueDetailAPI}?issueId=${issueId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const getAllRelatedCompany = async (companyId, token, callback) => {
  // console.log(API.getAllRelatedCompanyAPI)
  return fetch(`${API.getAllRelatedCompanyAPI}?companyId=${companyId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

//user password reset
export const resetUserPassword = async (token, data, callback) => {
  console.log("data", data);
  return fetch(`${API.userPasswordResetAPI}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

//admin password reset
export const resetAdminPassword = async (token, data) => {
  try {
    const response = await fetch(API.adminPasswordResetAPI, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "x-access-token": token,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error resetting password:", error);
    throw error;
  }
};

//pw_reset
export const passwordReset = async (data) => {
  try {
    const response = await fetch(API.passwordResetAPI, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        // "x-access-token": token,
        // "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error resetting password:", error);
    throw error;
  }
};

//updateCompanyStatus
export const updateCompanyStatus = async (
  token,
  companyId,
  company_status,
  callback
) => {
  return fetch(
    `${API.updateCompanyStatusAPI}?c_id=${companyId}&c_status=${company_status}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "x-access-token": token,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};
