/* eslint-disable default-case */
import { observable, action, runInAction, makeObservable, toJS } from "mobx";
import { toast } from "react-toastify";
import { RegisterFormValidator } from "../helper/formValidation";
import { CompanyRegisterFormValidator } from "../helper/formValidation";
import { UpdateFormValidator } from "../helper/formValidation";
import {
  registerCompany,
  createCompany,
  createCompanyNew,
  updateCompany,
  deleteUser,
  fetchAllUser,
  updateUser,
  fetchInviteLink,
  fetchSendInviteLink,
  fetchDriverLst,
  getUserPlan,
  editUser,
  updatePlan,
  updateUserStatus,
  getReports,
  changeStatus,
  getUserStatus,
  getChildCompanies,
  getAllSameLevelCompanies,
  deleteCompanyInfo,
  leaveUserUnit,
  getActivityLogs,
  getLogIssues,
  getAllRelatedCompany,
  getLogsNoti,
  editRemiderUser,
  getIssuesNoti,
  getIssueDetail,
  resetUserPassword,
  updateCompanyStatus,
} from "../network/fetchAdmin";
import { getCompanyList } from "../network/fetchLanding";
import { fetchUpdateCompanyLogo } from "../network/fetchAdmin";
import { fetchUpdateCompanyAscentAccId } from "../network/fetchAdmin";
import { getAllContract } from "../network/trackingAPI";
import Common from "./common";
import Driver from "./driver";
import DataEntry from "./dataEntry";
import { CreateUser, SendEmail } from "../network/fetchUser";
import { useLocation, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import { baseURL } from "../network/URL";
import { tickStep } from "d3";
import { getMonth } from "date-fns";
import moment from "moment";

class AdminPanel {
  cookies = new Cookies();
  companyId = this.cookies.get("company");
  level = this.cookies.get("level");
  company_name = this.cookies.get("companyName");
  companyName = "";
  editmode = undefined;
  userList = null;
  contractList = [];
  uploadLogoImage = "";
  photo = "";
  isEditAccountId = false;
  editAccountId = "editUser";
  userEditMode = "";
  tempData = {};
  username = "";
  isOpenDeleteModal = false;
  addNewVehicle = false;
  userNameToDelete = null;
  userIdToDelete = null;
  token = null;
  companies = [];
  selectedRowName = null;
  inviteLinkData = null;
  sendMail = "";
  drivers = [];
  all = { value: 0, label: "All" };
  driverOption = [];
  selectedDriver = { value: 0, label: "All" };
  filterDriver = [];
  editUserId = null;

  addVehicleId = null;

  userVehicle = null;

  userPlanOptions = [];
  userPlanId =
    this.userPlanOptions.length > 0 ? this?.userPlanOptions[0]?.value : "ads";
  userData = { companyId: this.companyId, password: "", confirmPassword: "" };
  selectedTab = "Company";
  userDetail = null;
  addNewUser = false;
  reportData = [];
  // index = this.userList?.findIndex(v => v.id === this.editUserId)
  editTempData = {};

  addVTempData = {};
  currentPlanName = "";
  userStatusOptions = [];
  userStatusId = "";

  addnewuserlist = [];

  vehicleName = ["Vehicle ABC", "Vehicle DEF", "Vehicle GHI"];
  fuelType = ["Disel", "Petrol"];

  email = " ";
  password = " ";
  role = " ";

  assigVehicle = [];
  availableVehicle = [];
  selectedVehicle = [];
  selectedCountry = "";
  selectedCompany = "";
  selectedIssue = "";
  selectedLevel = "";
  updateCompany = "";
  companyInfo = {
    companyId: this.companyId,
    registerDate: new Date(),
    expireDate: new Date(),
  };
  accountData = {
    companyId: this.companyId,
    registerDate: new Date(),
    expireDate: new Date(),
  };
  companyOptionData = {
    deleteCompanyOption: "childCompanies",
  };

  GetChildCompanies = [];

  GetAllSameLevelCompanies = [];

  userUnit = [];

  userStatusOptions = [];

  switchValue = "";
  selectedUser = "";

  selectedYear = 0;
  selectedMonth = 0;

  startDate = moment(new Date());
  endDate = moment(new Date());

  startDateFormat = this.startDate.startOf("day");
  endDateFormat = this.endDate.endOf("day");

  today = new Date();

  logsData = [];
  issuesData = [];
  isLoading = false;

  logsNoti = [];
  issueNoti = [];
  issueDetail = null;

  showView = "editUser";

  showLogView = "logs";

  validation = null;

  requiredFields = [];
  disableNextPage = true;
  currentPage = 1;
  state = {
    currentPage: 1,
  };
  prevState = null;
  infoModalView = false;
  passwordResetView = false;
  companyLevel = null;
  loading = false;
  updateStatus = 0;

  constructor() {
    makeObservable(this, {
      updateStatus: observable,
      company_name: observable,
      companyName: observable,
      editmode: observable,
      userEditMode: observable,
      userList: observable,
      contractList: observable,
      uploadLogoImage: observable,
      photo: observable,
      isEditAccountId: observable,
      tempData: observable,
      username: observable,
      isOpenDeleteModal: observable,
      userNameToDelete: observable,
      companies: observable,
      selectedRowName: observable,
      editAccountId: observable,
      inviteLinkData: observable,
      sendMail: observable,
      drivers: observable,
      driverOption: observable,
      filterDriver: observable,
      editUserId: observable,
      userPlanOptions: observable,
      userPlanId: observable,
      userData: observable,
      selectedTab: observable,
      userDetail: observable,
      addNewUser: observable,
      reportData: observable,
      editTempData: observable,
      addVTempData: observable,
      currentPlanName: observable,
      userStatusOptions: observable,
      userStatusId: observable,
      addnewuserlist: observable,
      assigVehicle: observable,
      availableVehicle: observable,
      selectedVehicle: observable,
      addVehicleId: observable,
      userVehicle: observable,
      selectedCountry: observable,
      selectedCompany: observable,
      selectedIssue: observable,
      companyInfo: observable,
      companyOptionData: observable,
      GetChildCompanies: observable,
      GetAllSameLevelCompanies: observable,
      updateCompany: observable,
      userUnit: observable,
      addNewVehicle: observable,
      selectedLevel: observable,
      switchValue: observable,
      selectedUser: observable,
      accountData: observable,
      selectedYear: observable,
      selectedMonth: observable,
      showView: observable,
      validation: observable,
      requiredFields: observable,
      disableNextPage: observable,
      currentPage: observable,
      state: observable,
      prevState: observable,
      infoModalView: observable,
      passwordResetView: observable,
      companyLevel: observable,

      // Activity Logs
      logsData: observable,
      isLoading: observable,
      startDate: observable,
      endDate: observable,
      today: observable,
      issuesData: observable,
      showLogView: observable,

      //Logs Noti
      logsNoti: observable,
      issueNoti: observable,

      issueDetail: observable,
      loading: observable,

      _handleTextChange: action.bound,
      _handleEdit: action.bound,
      _fetchUserList: action.bound,
      _handleUploadImage: action.bound,
      _resetImage: action.bound,
      _setPhotoImage: action.bound,
      _handleEditAccountId: action.bound,
      _handleAccountIdSave: action.bound,
      _handleAccountIdOnChange: action.bound,
      _handleEditUser: action.bound,
      _handleUserInputChange: action.bound,
      _handleConfirmEdit: action.bound,
      _handleDeleteUser: action.bound,
      _openDeleteModal: action.bound,
      _fetchCompanyList: action.bound,
      _fetchInviteLink: action.bound,
      _fetchInviteLinkSend: action.bound,
      _handleMailChange: action.bound,
      _fetchDriverLst: action.bound,
      _handleSelectedDriver: action.bound,
      setEditUser: action.bound,
      getUserPlan: action.bound,
      setUserPlanId: action.bound,
      getUserStatus: action.bound,
      setUserStatusId: action.bound,
      _handleEditTextChange: action.bound,
      _handleSubmitEdit: action.bound,
      _handleUpdatePlan: action.bound,
      _handleUpdateUserStatus: action.bound,
      _handleInputChange: action.bound,
      _handleCreateUser: action.bound,
      setSelectedTab: action.bound,
      _handleUserDetail: action.bound,
      _setAddNewUser: action.bound,
      _fetchReportList: action.bound,
      _handleApprove: action.bound,
      _handleTestUserListOnClick: action.bound,
      _handleUserAssignVehicle: action.bound,
      // _handleTestAssignVehicleOnClick: action.bound
      setSelectedCountry: action.bound,
      setSelectedCompany: action.bound,
      _handleCreateCompany: action.bound,
      _handleEditCompany: action.bound,
      _handleUpdateCompany: action.bound,
      _handleRadioChange: action.bound,

      _handleCloseModel: action.bound,
      toDataURL: action.bound,
      urlToObject: action.bound,

      _fetchGetChildCompanies: action.bound,
      _fetchGetAllSameLevelCompanies: action.bound,
      _handleDeleteCompany: action.bound,
      _fetchdeleteCompanyInfo: action.bound,
      _handleConfirmBeforeSubmitEdit: action.bound,
      _setAssignNewVehicleUser: action.bound,
      _handleChangeRegisterDate: action.bound,
      _handleChangeExpireDate: action.bound,
      _handleFormClear: action.bound,
      _handleCompanyInputChange: action.bound,
      setSelectedLevel: action.bound,
      setAssignSwitchValue: action.bound,
      setSwitchValue: action.bound,
      _handleCreateCompanyNew: action.bound,
      _handleCheckBox: action.bound,
      setSelectedUser: action.bound,
      _handleRegisterCompany: action.bound,
      setSelectedYear: action.bound,
      setSelectedMonth: action.bound,
      handleViewChange: action.bound,
      _handlegonextpage: action.bound,
      _handleRequiredField: action.bound,
      handleNextPage: action.bound,
      handlePrevPage: action.bound,
      handleClose: action.bound,
      _handleNewCreateNewCompany: action.bound,
      setInfoModalView: action.bound,
      setPasswordResetView: action.bound,
      _handleResetPasswordSendEmail: action.bound,

      // Activity logs
      _handleGetActivityLogs: action.bound,
      setLoading: action.bound,
      _handleStartDateChange: action.bound,
      _handleEndDateChange: action.bound,
      _handleSelectCustom: action.bound,
      _handleGetLogIssues: action.bound,
      _handleViewChange: action.bound,

      //logs noti
      _handleGetLogsNoti: action.bound,

      //issues noti
      _handleGetIssuesNoti: action.bound,

      _handleIssueDetail: action.bound,
      setSelectedIssue: action.bound,

      setCompanyLevel: action.bound,
      //notification
      // _handleGetAllRelatedCompany: action.bound

      handleReminderSwitch: action.bound,
      _handleReminderCheck: action.bound,
      handleSetLoading: action.bound,
      _handleUpdateCompanyStatus: action.bound,
    });
  }

  _handleViewChange(view) {
    runInAction(() => {
      this.showLogView = view;
    });
  }

  _handleRequiredField(value) {
    if (!Array.isArray(this.requiredFields)) {
      this.requiredFields = [];
    }
    this.requiredFields.push(this.companyInfo);
    console.log(this.requiredFields, "requiredfield====>");
  }

  setInfoModalView(view) {
    this.infoModalView = view;
  }
  setPasswordResetView(view) {
    this.passwordResetView = view;
  }

  handleViewChange(view) {
    runInAction(() => {
      this.showView = view;
    });
  }

  handleSetLoading = (value) => {
    runInAction(() => {
      this.loading = value;
    });
  };

  setLoading(value) {
    this.isLoading = value;
  }

  setSelectedUser(value) {
    this.selectedUser = value;
  }

  _handleStartDateChange(date) {
    // const startDateMidnight = new Date();
    // startDateMidnight.setHours(0, 0, 0, 0);
    this.startDate = date;
  }

  _handleEndDateChange(date) {
    // const endDateBeforeMidnight = new Date();
    // endDateBeforeMidnight.setHours(23, 59, 59, 999);
    this.endDate = date;
  }

  _handleSelectCustom(date, dateString) {
    this.startDate = dateString[0];
    this.endDate = dateString[1];
  }

  _handleSelectedDriver(data) {
    this.selectedDriver = data;

    if (data.value === 0) {
      this.filterDriver = this.drivers;
    } else {
      this.filterDriver = this.drivers?.filter((driver) => {
        return driver.id === data.value;
      });
    }
  }

  _fetchDriverLst() {
    fetchDriverLst(Common.token, this.companyId, (err, data) => {
      if (data) {
        this.drivers = data;
        this.filterDriver = data;
        this.driverOption = [];
        this.driverOption.push(this.all);

        data.map((v) => {
          this.driverOption.push({
            value: v.id,
            label: v.username,
          });
        });

        this.selectedDriver = this.driverOption[0];
        Driver._handleSelectedDriver(this.drivers[0]?.id);
      } else {
        toast.error(err);
      }
    });
  }

  _handleMailChange(value) {
    this.sendMail = value;
  }

  _fetchInviteLinkSend() {
    fetchSendInviteLink(
      Common.token,
      this.sendMail,
      this.inviteLinkData?.invite_link,
      (err, data) => {
        if (data.error) {
          toast.error(data.error);
        } else {
          toast.success(data.payload);
        }
      }
    );
  }

  _fetchInviteLink() {
    fetchInviteLink(
      Common.token,
      this.companyId,
      Driver.userId,
      (err, data) => {
        if (data) {
          this.inviteLinkData = data;
        } else {
          toast.error(err);
        }
      }
    );
  }

  _fetchCompanyList(role, companyId) {
    // let cId = "";
    // if (Common.role !== "super_admin") {
    //   cId = Common.companyId;
    // }

    getCompanyList(Common.token, "admin", this.companyId, (err, data) => {
      if (data) {
        if (data.message === "Token Expired!") {
          window.location.pathname = "/";
          toast.info(data.message);
        }
        this.companies = data;
        this.selectedRowName = data[0];
        return data[0];
      } else {
        return null;
      }
    });
  }

  _handleAccountIdOnChange(e) {
    this.editAccountId = e.target.value;
  }

  _handleTestUserListOnClick(e) {
    this.addnewuserlist.push(this.userData);
    console.log("userlisttest", toJS(this.addnewuserlist.map((v) => toJS(v))));
  }

  _handleAccountIdSave(companyId) {
    this.isEditAccountId = !this.isEditAccountId;
    fetchUpdateCompanyAscentAccId(
      Common.token,
      companyId,
      this.editAccountId,
      (err, data) => {
        if (err) {
          toast.error(err);
        } else {
          toast.success("Upload Ansent Account ID Successful");
        }
      }
    );
  }

  _handleEditAccountId(value) {
    this.isEditAccountId = !this.isEditAccountId;
    this.editAccountId = value;
  }

  _handleUploadImage(image, companyId) {
    this.uploadLogoImage = URL.createObjectURL(image.target.files[0]);
    this.photo = image.target.files[0];

    // this._fetchUpdateCompanyLogo(companyId);
  }

  _fetchUpdateCompanyLogo(companyId) {
    var formdata = new FormData();
    formdata.append("photo", this.photo, "blank.jpeg");

    fetchUpdateCompanyLogo(Common.token, companyId, formdata, (err, data) => {
      if (err) {
        toast.error(err);
      } else {
        toast.success("Upload Image Successful");

        //this._fetchCompanyList();
      }
    });
  }

  _resetImage(companyId) {
    this.uploadLogoImage = "";
    this.photo = null;
    //this._fetchUpdateCompanyLogo(companyId);
  }

  _setPhotoImage(value) {
    this.uploadLogoImage = value;
    this.photo = value;
  }

  _handleTextChange = (e, id) => {
    this.companyName = e.target.value;
  };

  _handleEdit(name) {
    runInAction(() => {
      this.editmode = name;
    });
  }

  _fetchUserList(token, companyId) {
    fetchAllUser(token, companyId, (err, data) => {
      if (!data?.message) {
        runInAction(() => {
          if (Common.userName === "co2x") {
            this.userList = data.filter(
              (value) => value.companyId === "24445513"
            );
          } else {
            this.userList = data;
          }
        });
      } else {
        if (data.message === "Token Expired!") {
          window.location.pathname = "/";
          toast.info(data.message);
        } else toast.error(data.message);
      }
    });
  }

  _getAllContract(token) {
    getAllContract(token, (err, data) => {
      if (!data?.message) {
        runInAction(() => {
          this.contractList = data.data;
        });
      } else {
        runInAction(() => {
          toast.error(err.message);
        });
      }
    });
  }

  _handleEditUser(token, user) {
    runInAction(() => {
      this.userEditMode = user ? user.id : "";
    });
    this.tempData = user && {
      username: user.username,
      email: user.email,
      role: user.role,
      uid: user.id,
    };
  }

  _handleUserInputChange(e, key) {
    if (key === "username")
      this.tempData = { ...this.tempData, username: e.target.value };
    else if (key === "email")
      this.tempData = { ...this.tempData, email: e.target.value };
    else if (key === "role")
      this.tempData = { ...this.tempData, role: e.value };
  }

  _handleConfirmEdit(token) {
    const role = Common.role;
    const companyId = Common.companyId;
    updateUser(token, this.tempData, (err, data) => {
      if (data.message === "User Updated!") {
        this.userEditMode = "";
        this.tempData = {};
        this._fetchUserList(token, companyId, role);
        toast.success(data.message);
      }
    });
  }

  _openDeleteModal(token, user) {
    this.isOpenDeleteModal = true;
    this.token = token;
    this.userNameToDelete = user.username;
    this.userIdToDelete = user.id;
  }

  _handleUserListUpdate() {
    const role = Common.role;
    const companyId = this.companyId;
  }

  _handleDeleteUser(userId) {
    const role = Common.role;
    const companyId = this.companyId;
    deleteUser(Common.token, userId, (err, data) => {
      if (data.error) {
        toast.error(data.error);
      } else {
        this._fetchUserList(Common.token, companyId, role);
        toast.success("Remove account successful.");
      }
    });
  }

  setEditUser(id, plan_name) {
    this.currentPlanName = plan_name;

    this._setAddNewUser(false);
    this.editUserId = id;
    this.addNewVehicle = false;
    const index = this.userList.findIndex((v) => v.id === id);
    this.editTempData = {
      username: this.userList[index]?.username,
      email: this.userList[index]?.email,
      role: this.userList[index]?.role,
      id: id,
    };
  }

  _setAddNewUser(value) {
    this.addNewVehicle = false;
    this.editUserId = null;
    this.addNewUser = value;
  }

  _setAssignNewVehicle(value) {
    this.addNewUser = value;
  }
  _setAssignNewVehicleUser(value) {
    this.addNewVehicle = value;
    this.addNewUser = null;
    this.editUserId = null;
  }

  setAddNewVehicle(id, plan_name) {
    this.currentPlanName = plan_name;
    this._setAddNewUser(false);
    this.addVehicleId = id;
    const addV = this.userList.findIndex((v) => v.id === id);
    this.vTempData = {
      username: this.userList[addV].username,
      role: this.userList[addV].role,
      id: id,
    };
  }

  _setAddNewVehicle() {
    this.addVehicleId = null;
  }

  getUserStatus() {
    getUserStatus(Common.token, (err, data) => {
      if (data) {
        if (data.message === "Token Expired!") {
          window.location.pathname = "/";
        } else {
          this.userStatusOptions = data.response?.map((v) => ({
            value: v.value,
            label: v.label,
          }));
        }
      }
      console.log("User Status options", toJS(this.userStatusOptions));
    });
  }

  getUserPlan() {
    getUserPlan(this.token, (err, data) => {
      if (data) {
        if (data.message === "Token Expired!") {
          window.location.pathname = "/";
        } else {
          this.planId = data[0].userTypeId;
          this.userPlanOptions = data.map((v) => ({
            label: v.plan_name,
            value: v.userTypeId,
          }));
        }
        const defaultPlanId = data.filter(
          (v) => v.plan_name === this.currentPlanName
        )[0].userTypeId;
        this.setUserPlanId(defaultPlanId);
      }
    });
  }

  setUserPlanId(id) {
    this.userPlanId = id;
    console.log("User Plan Id ===>", this.userPlanId);
  }

  setUserStatusId(id) {
    this.userStatusId = id;
  }

  _handleEditTextChange(e, id, field) {
    const index = this.userList.findIndex((v) => v.id === id);

    switch (field) {
      case "companyId":
        this.editTempData = { ...this.editTempData, companyId: e.value };
        break;
      case "username":
        this.editTempData = { ...this.editTempData, username: e.target.value };
        break;
      case "email":
        this.editTempData = { ...this.editTempData, email: e.target.value };
        break;
      case "role":
        this.editTempData = { ...this.editTempData, role: e.target.value };
        break;
      // case 'username': this.userList[index] = { ...this.userList[index], username: e.target.value }; break;
      // eCompanycase 'email': this.userList[index] = { ...this.userList[index], email: e.target.value }; break;
      // case 'role': this.userList[index] = { ...this.userList[index], role: e.target.value }; break;
    }
  }

  _handleSubmitEdit(data, userId) {
    editUser(Common.token, userId, data, (err, data) => {
      if (data) {
        if (data.message === "Token Expired!") window.location.pathname = "/";
        else toast.success(data.message);
        this._fetchUserList(Common.token, this.companyId, Common.role);
      }
    });
  }

  _handleConfirmBeforeSubmitEdit(userId, callback) {
    leaveUserUnit(Common.token, userId, (err, data) => {
      if (data) {
        if (data.message === "Token Expired!") window.location.pathname = "/";
        else callback(data);
      }
    });
  }

  _handleUpdatePlan(userId) {
    updatePlan(Common.token, userId, this.userPlanId, (err, data) => {
      if (data) {
        if (data.message === "Token Expired!") window.location.pathname = "/";
        else toast.success(data.message);
        this._fetchUserList(Common.token, this.companyId, Common.role);
      }
    });
  }

  _handleUpdateUserStatus(userId) {
    updateUserStatus(Common.token, userId, this.userStatusId, (err, data) => {
      if (data) {
        if (data.message === "Token Expired!") window.location.pathname = "/";
        else toast.success(data.message);
        this._fetchUserList(Common.token, this.companyId, Common.role);
      }
    });
  }

  _handleInputChange = (user) => {
    const userInfo = { ...this.userData, ...user };
    this.userData = userInfo;
  };

  _handleInputChangeNew = (user) => {
    runInAction(() => {
      const userInfo = { ...this.accountData, ...user };
      this.accountData = userInfo;
    });
  };

  _handleCompanyInputChange = (companyInfo) => {
    console.log("register info ====>", companyInfo);
    const cInfo = { ...this.companyInfo, ...companyInfo };
    this.companyInfo = cInfo;
    console.log("register company info ===>", this.companyInfo);
  };

  _handleCheckBox = (value) => {
    const cInfo = { ...this.companyInfo, ...value };
    this.companyInfo = cInfo;
  };

  _handleFormClear = () => {
    this.setSelectedLevel(null);
    this.companyInfo.companyName = "";
    this.companyInfo.country = "";
    this.companyInfo.type = "";
    this.companyInfo.ascentAccountId = "";
    this.companyInfo.companyRegNumber = "";
    this.userData.username = "";
    this.userData.email = "";
    this.userData.password = "";
    this.userData.confirmPassword = "";

    this.companyInfo.incorporation = "";
    this.companyInfo.sector = "";
    this.companyInfo.size = "";
    this.companyInfo.revenue = "";
    this.companyInfo.website = "";
    this.companyInfo.phone = "";

    this.userData.designation = "";
    this.setSelectedUser(null);
  };

  _handleCreateUser = () => {
    const err = RegisterFormValidator(this.userData);

    if (err.userNameErr) {
      toast.error(err.userNameErr);
    } else if (err.emailEmptyErr) {
      toast.error(err.emailEmptyErr);
    } else if (err.emailErr) {
      toast.error(err.emailErr);

      // } else if (err.passwordErr) {
      //   toast.error(err.passwordErr);
      // } else if (err.confirmPasswordErr) {
      //   toast.error(err.confirmPasswordErr);
      // } else if (err.passwordMatchErr) {
      //   toast.error(err.passwordMatchErr);
    } else {
      // Password validation
      // console.log("userPassword", this.userData.password);
      // const passwordRegex =
      //   // /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
      //   /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*#?&.])[A-Za-z\d@$!%*#?&.]{8,}$/;
      // if (!passwordRegex.test(this.userData.password)) {
      //   toast.error(
      //     "Password must have at least 8 characters, including alphabet, number, and special characters."
      //   );
      //   return;
      // }

      if (!this.userData.role) {
        this.userData.role = "admin";
      }

      CreateUser(Common.token, this.userData, (err, data) => {
        if (data.error) {
          toast.error(data.error);
        } else {
          toast.success("Account creation is successful.");
          this._setAddNewUser(false);
          this._handleFormClear();
          this._fetchUserList(Common.token, this.companyId, Common.role);
        }
      });
    }
  };

  setSelectedTab = (value) => {
    this.selectedTab = value;
  };

  _routeToUserlist = (history) => {
    this.selectedTab = "User";
    this.userDetail = null;
    history.push("/admin_panel");
  };

  _handleUserDetail = (id, history) => {
    this._fetchReportList({ userId: id });
    this.userDetail = this.userList.find((v) => v.id === id);
    history.push(`?userId=${id}`);
    console.log(toJS(this.userDetail));
  };

  _handleUserAssignVehicle = (id, history) => {
    this._fetchReportList({ userId: id });
    this.userVehicle = this.userList.find((v) => v.id === id);
    history.push(`?userId=${id}`);
  };

  _fetchReportList = (param) => {
    this.reportData = null;
    getReports(Common.token, param, (err, data) => {
      if (data) {
        if (data.message === "Token Expired!") window.location.pathname = "/";
        else this.reportData = data;
      }
    });
    console.log("report data", this.reportData);
  };

  _handleApprove = (id, status) => {
    changeStatus(Common.token, id, status, (err, data) => {
      const location = window.location.href;
      var url = new URL(location);
      var userId = url.searchParams.get("userId");

      if (data) {
        if (data.message === "Token Expired!") window.location.pathname = "/";
        else if (data.message === "Change status Successful") {
          if (status !== 0) {
            toast.success("Approved request successfully!");
          } else toast.info("Canceled request successfully!");
        }

        if (userId) {
          this._fetchReportList({ userId: userId });
        } else {
          this._fetchReportList();
        }
      }
    });
  };

  setSelectedCountry(value) {
    this.selectedCountry = value;
  }
  setSelectedCompany(value) {
    this.selectedCompany = value;
  }

  setSelectedIssue(value) {
    this.selectedIssue = value;
  }

  setUpdateCompany(value) {
    this.updateCompany = value;
  }

  _handleCreateCompany() {
    const company = {
      name: this.companyInfo.companyName,
      country: this.companyInfo.country,
      type: this.companyInfo.type,
      ascentAccountId: this.companyInfo.ascentAccountId,
      companyRegNumber: this.companyInfo.companyRegNumber,
      registerDate: this.companyInfo.registerDate,
      expireDate: this.companyInfo.expireDate,
      createdBy: this.companyId,
      createdBylevel: this.companyInfo.level,
    };

    const user = {
      username: this.userData.username,
      email: this.userData.email,
      password: this.userData.password,
    };

    let data = {
      name: this.userData.companyName,
      country: this.companyInfo.country,
      type: this.companyInfo.type,
      ascentAccountId: this.companyInfo.ascentAccountId,
      username: this.userData.username,
      email: this.userData.email,
      password: this.userData.password,
      confirmPassword: this.userData.confirmPassword,
    };

    const formData = new FormData();

    formData.append("company", JSON.stringify(company));
    formData.append("user", JSON.stringify(user));
    formData.append("photo", this.photo);

    const err = RegisterFormValidator({
      name: this.companyInfo.companyName,
      country: this.companyInfo.country,
      type: this.companyInfo.type,
      ascentAccountId: this.companyInfo.ascentAccountId,
      username: this.userData.username,
      email: this.userData.email,
      password: this.userData.password,
      confirmPassword: this.userData.confirmPassword,
    });

    if (err.nameErr) {
      toast.error(err.nameErr);
    } else if (err.countryErr) {
      toast.error(err.countryErr);
    } else if (err.typeErr) {
      toast.error(err.typeErr);
    } else if (err.userNameErr) {
      toast.error(err.userNameErr);
    } else if (err.emailEmptyErr) {
      toast.error(err.emailEmptyErr);
    } else if (err.emailErr) {
      toast.error(err.emailErr);
    } else if (err.passwordErr) {
      toast.error(err.passwordErr);
    } else if (err.confirmPasswordErr) {
      toast.error(err.confirmPasswordErr);
    } else if (err.passwordMatchErr) {
      toast.error(err.passwordMatchErr);
    } else {
      createCompany(Common.token, formData, (err, data) => {
        if (data.message === "Company creation successful") {
          this._fetchCompanyList(Common.role, Common.companyId);
          toast.success(data.message);
          window.location.reload();
        } else {
          toast.error(err.message);
        }
      });
    }
  }

  handleNextPage() {
    runInAction(() => {
      this.currentPage += 1;
    });
  }

  handlePrevPage() {
    runInAction(() => {
      this.currentPage -= 1;
    });
  }

  handleClose() {
    runInAction(() => {
      this.currentPage = 0;
    });
  }

  _handlegonextpage() {
    try {
      const err = RegisterFormValidator({
        name: this.companyInfo.name,
        country: this.companyInfo.country,
        company_incorporation: this.companyInfo.company_incorporation,
        level: this.companyInfo.level,
        address: this.companyInfo.address,
        website: this.companyInfo.website,
        company_size: this.companyInfo.company_size,
        revenue: this.companyInfo.revenue,
        sector: this.companyInfo.sector,
        isCompact_signup: this.companyInfo.isCompact_signup,
        isWrittenEnv_policy: this.companyInfo.isWrittenEnv_policy,
        registration_number: this.companyInfo.registration_number,
        isRegisterToESGpedia: this.companyInfo.isRegisterToESGpedia,
        isDeclarationOfLegalBusiness:
          this.companyInfo.isDeclarationOfLegalBusiness,
      });

      if (err.nameErr) {
        toast.error(err.nameErr);
      } else if (err.countryErr) {
        toast.error(err.countryErr);
      } else if (err.incorporationErr) {
        toast.error(err.incorporationErr);
      } else if (err.levelErr) {
        toast.error(err.levelErr);
      } else if (err.addressErr) {
        toast.error(err.addressErr);
      } else if (err.websiteErr) {
        toast.error(err.websiteErr);
      } else if (err.sizeErr) {
        toast.error(err.sizeErr);
      } else if (err.revenueErr) {
        toast.error(err.revenueErr);
      } else if (err.industrySectorErr) {
        toast.error(err.industrySectorErr);
      } else if (err.signupErr) {
        toast.error(err.signupErr);
      } else if (err.policyErr) {
        toast.error(err.policyErr);
      } else if (err.registErr) {
        toast.error(err.registErr);
      } else if (err.declareErr) {
        toast.error(err.declareErr);
      } else {
        this.handleNextPage();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  _handleNewCreateNewCompany() {
    const company = {
      name: this.companyInfo.name,
      company_incorporation: this.companyInfo.company_incorporation,
      country: this.companyInfo.country,
      sector: this.companyInfo.sector,
      ascentAccountId: this.companyInfo.ascentAccountId,
      registration_number: this.companyInfo.registration_number,
      website: this.companyInfo.website,
      address: this.companyInfo.address,
      company_size: this.companyInfo.company_size,
      revenue: this.companyInfo.revenue,
      leiNo: this.companyInfo.leiNo,
      isCompact_signup: this.companyInfo.isCompact_signup,
      isWrittenEnv_policy: this.companyInfo.isWrittenEnv_policy,
      isRegisterToESGpedia:
        this.companyInfo.isRegisterToESGpedia === true ? "1" : "0",
      isDeclarationOfLegalBusiness:
        this.companyInfo.isDeclarationOfLegalBusiness,
      registerDate: this.companyInfo.registerDate,
      expireDate: this.companyInfo.expireDate,
      createdBy: this.companyId,
      createdBylevel: this.companyInfo.level,
    };

    const account = {
      email: this.accountData.email,
      designation: this.accountData.designation,
      first_name: this.accountData.first_name,
      last_name: this.accountData.last_name,
      password: this.accountData.password,
      username: this.userData.username,
      isFirstTimeLogin: 1,
    };

    const formData = new FormData();

    formData.append("company", JSON.stringify(company));
    formData.append("account", JSON.stringify(account));
    formData.append("photo", this.photo);

    const err = RegisterFormValidator({
      name: this.companyInfo.name,
      company_incorporation: this.companyInfo.company_incorporation,
      sector: this.companyInfo.sector,
      address: this.companyInfo.address,
      website: this.companyInfo.website,
      company_size: this.companyInfo.company_size,
      revenue: this.companyInfo.revenue,
      leiNo: this.companyInfo.leiNo,
      isCompact_signup: this.companyInfo.isCompact_signup || false,
      isWrittenEnv_policy: this.companyInfo.isWrittenEnv_policy || false,
      registration_number: this.companyInfo.registration_number,
      isRegisterToESGpedia:
        this.companyInfo.isRegisterToESGpedia === true ? true : false,
      isDeclarationOfLegalBusiness: this.companyInfo
        .isDeclarationOfLegalBusiness
        ? true
        : false,
      email: this.accountData.email,
      designation: this.accountData.designation,
      first_name: this.accountData.first_name,
      last_name: this.accountData.last_name,
      password: this.accountData.password,
      confirmPassword: this.accountData.confirmPassword,
    });

    if (err.firstNameErr) {
      toast.error(err.firstNameErr);
    } else if (err.lastNameErr) {
      toast.error(err.lastNameErr);
    } else if (err.emailEmptyErr) {
      toast.error(err.emailEmptyErr);
    } else if (err.emailErr) {
      toast.error(err.emailErr);
    } else if (err.passwordErr) {
      toast.error(err.passwordErr);
    } else if (err.confirmPasswordErr) {
      toast.error(err.confirmPasswordErr);
    } else if (err.passwordMatchErr) {
      toast.error(err.passwordMatchErr);
    } else {
      createCompanyNew(Common.token, formData, (err, data) => {
        if (err) {
          toast.error(err.message);
        } else {
          this._fetchCompanyList(Common.role, Common.companyId);
          this._handleFormClear();
          toast.success(data.message);
          // window.location.reload();
        }
      });
    }
  }

  // old create company modal api

  _handleCreateCompanyNew() {
    const company = {
      name: this.companyInfo.name,
      company_incorporation: this.companyInfo.company_incorporation,
      country: this.companyInfo.country,
      sector: this.companyInfo.sector,
      ascentAccountId: this.companyInfo.ascentAccountId,
      registration_number: this.companyInfo.registration_number,
      website: this.companyInfo.website,
      address: this.companyInfo.address,
      company_size: this.companyInfo.company_size,
      revenue: this.companyInfo.revenue,
      leiNo: this.companyInfo.leiNo,
      isCompact_signup: this.companyInfo.isCompact_signup,
      isWrittenEnv_policy: this.companyInfo.isWrittenEnv_policy,
      isDeclarationOfLegalBusiness:
        this.companyInfo.isDeclarationOfLegalBusiness,
      registerDate: this.companyInfo.registerDate,
      expireDate: this.companyInfo.expireDate,
      createdBy: this.companyId,
      createdBylevel: this.companyInfo.level,
    };

    const account = {
      email: this.accountData.email,
      designation: this.accountData.designation,
      first_name: this.accountData.first_name,
      last_name: this.accountData.last_name,
      password: this.accountData.password,
      username: this.userData.username,
    };

    const formData = new FormData();

    formData.append("company", JSON.stringify(company));
    formData.append("account", JSON.stringify(account));
    formData.append("photo", this.photo);

    const err = RegisterFormValidator({
      name: this.companyInfo.name,
      company_incorporation: this.companyInfo.company_incorporation,
      sector: this.companyInfo.sector,
      address: this.companyInfo.address,
      website: this.companyInfo.website,
      company_size: this.companyInfo.company_size,
      revenue: this.companyInfo.revenue,
      leiNo: this.companyInfo.leiNo,
      isCompact_signup: this.companyInfo.isCompact_signup || false,
      isWrittenEnv_policy: this.companyInfo.isWrittenEnv_policy || false,
      isRegisterToESGpedia:
        this.companyInfo.isRegisterToESGpedia === true ? true : false,
      isDeclarationOfLegalBusiness: this.companyInfo
        .isDeclarationOfLegalBusiness
        ? true
        : false,
      registration_number: this.companyInfo.registration_number,
      email: this.accountData.email,
      designation: this.accountData.designation,
      first_name: this.accountData.first_name,
      last_name: this.accountData.last_name,
      password: this.accountData.password,
      confirmPassword: this.accountData.confirmPassword,
    });
    if (err.nameErr) {
      toast.error(err.nameErr);
    } else if (err.countryErr) {
      toast.error(err.countryErr);
    } else if (err.incorporationErr) {
      toast.error(err.incorporationErr);
    } else if (err.industrySectorErr) {
      toast.error(err.industrySectorErr);
    } else if (err.levelErr) {
      toast.error(err.levelErr);
    } else if (err.addressErr) {
      toast.error(err.addressErr);
    } else if (err.websiteErr) {
      toast.error(err.websiteErr);
    } else if (err.sizeErr) {
      toast.error(err.sizeErr);
    } else if (err.revenueErr) {
      toast.error(err.revenueErr);
    } else if (err.signupErr) {
      toast.error(err.signupErr);
    } else if (err.policyErr) {
      toast.error(err.policyErr);
    } else if (err.declareErr) {
      toast.error(err.declareErr);
    } else if (err.registErr) {
      toast.error(err.registErr);
    } else if (err.firstNameErr) {
      toast.error(err.firstNameErr);
    } else if (err.lastNameErr) {
      toast.error(err.lastNameErr);
    } else if (err.emailEmptyErr) {
      toast.error(err.emailEmptyErr);
    } else if (err.emailErr) {
      toast.error(err.emailErr);
    } else if (err.passwordErr) {
      toast.error(err.passwordErr);
    } else if (err.confirmPasswordErr) {
      toast.error(err.confirmPasswordErr);
    } else if (err.passwordMatchErr) {
      toast.error(err.passwordMatchErr);
    } else {
      createCompanyNew(Common.token, formData, (err, data) => {
        if (err) {
          toast.error(err.message);
        } else {
          this._fetchCompanyList(Common.role, Common.companyId);
          this._handleFormClear();
          toast.success(data.message);
          window.location.reload();
        }
      });
    }
  }

  _handleRegisterCompany() {
    const company = {
      id: this.selectedCompany,
      incorporation: this.companyInfo.incorporation,
      sector: this.companyInfo.sector,
      website: this.companyInfo.website,
      size: this.companyInfo.size,
      revenue: this.companyInfo.revenue,
      phone: this.companyInfo.phone,

      compact_signup: this.companyInfo.compact_signup || "NO",
      writtenEnv_policy: this.companyInfo.writtenEnv_policy || "NO",
      declarationOfLegalBusiness: this.companyInfo.declarationOfLegalBusiness
        ? this.companyInfo.declarationOfLegalBusiness
        : false,
    };

    const account = {
      id: this.selectedUser?.id,
      designation: this.userData.designation,
    };

    let data = {
      company: {
        id: this.selectedCompany,
        incorporation: this.companyInfo.incorporation,
        sector: this.companyInfo.sector,
        website: this.companyInfo.website,
        size: this.companyInfo.size,
        revenue: this.companyInfo.revenue,
        phone: this.companyInfo.phone,

        compact_signup: this.companyInfo.compact_signup || "NO",
        writtenEnv_policy: this.companyInfo.writtenEnv_policy || "NO",
        declarationOfLegalBusiness: this.companyInfo.declarationOfLegalBusiness
          ? this.companyInfo.declarationOfLegalBusiness
          : false,
      },
      account: {
        id: this.selectedUser?.id,
        designation: this.userData.designation,
      },
    };

    // const formData = new FormData();

    // formData.append('company', JSON.stringify(company))
    // formData.append('account', JSON.stringify(account))
    // formData.append('photo', this.photo)

    const err = CompanyRegisterFormValidator({
      incorporation: this.companyInfo.incorporation,
      sector: this.companyInfo.sector,
      website: this.companyInfo.website,
      size: this.companyInfo.size,
      revenue: this.companyInfo.revenue,
      phone: this.companyInfo.phone,
      designation: this.userData.designation,
    });

    if (err.incorporationErr) {
      toast.error(err.incorporationErr);
    } else if (err.sectorErr) {
      toast.error(err.sectorErr);
    } else if (err.websiteErr) {
      toast.error(err.websiteErr);
    }

    // else if (err.phoneErr) {
    //   toast.error(err.phoneErr)
    // }
    else if (err.sizeErr) {
      toast.error(err.sizeErr);
    } else if (err.revenueErr) {
      toast.error(err.revenueErr);
    } else if (err.designationErr) {
      toast.error(err.designationErr);
    } else {
      registerCompany(Common.token, data, (err, data) => {
        if (err) {
          toast.error(err.message);
        } else {
          this._fetchCompanyList(Common.role, Common.companyId);
          toast.success(data.message);
          // window.location.reload()
        }
      });
    }
  }

  _handleEditCompany(company) {
    console.log("companyINfo", toJS(company));
    const userInfo = { ...this.companyInfo, ...company };

    this.companyInfo = userInfo;
    this.setAssignSwitchValue(this.companyInfo.data_import);
    this.companyInfo.expireDate = this.companyInfo.expireDate
      ? new Date(this.companyInfo.expireDate)
      : "";
    this.companyInfo.registerDate = this.companyInfo.registerDate
      ? new Date(this.companyInfo.registerDate)
      : "";

    const photoUrl = baseURL + this.companyInfo.logo;

    this.urlToObject(photoUrl);
  }

  _handleDeleteCompany(company) {
    const userInfo = { ...this.userData, ...company };
    this.userData = userInfo;
  }

  urlToObject = async (image) => {
    const response = await fetch(image);
    // here image is url/location of image
    const blob = await response.blob();
    const file = new File([blob], "image.jpg", { type: blob.type });

    this.photo = file;
  };

  toDataURL = (url) => {
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result);
            };
            reader.onerror = reject;
            // reader.readAsArrayBuffer(blob)
            // console.log(url, blob)
          })
      );
  };

  _handleUpdateCompany() {
    const company = {
      id: this.companyInfo.id,
      name: this.companyInfo.name,
      country: this.companyInfo.country,
      type: this.companyInfo.type,
      companyRegNumber: this.companyInfo.companyRegNumber,
      registerDate: this.companyInfo.registerDate,
      expireDate: this.companyInfo.expireDate,
      level: this.companyInfo.level,
      data_import: this.switchValue ? 1 : 0,
    };

    const formData = new FormData();

    formData.append("company", JSON.stringify(company));
    formData.append("photo", this.photo);

    const err = UpdateFormValidator({
      name: this.companyInfo.name,
      country: this.companyInfo.country,
      type: this.companyInfo.type,
    });

    if (err.nameErr) {
      toast.error(err.nameErr);
    } else if (err.countryErr) {
      toast.error(err.countryErr);
    } else if (err.typeErr) {
      toast.error(err.typeErr);
    } else {
      updateCompany(Common.token, formData, (err, data) => {
        this.cookies.set("data_import_access", company.data_import);
        if (data) {
          toast.success(data.message);
          this._fetchCompanyList(Common.role, Common.companyId);
          // window.location.reload();
        } else {
          toast.error(err.message);
        }
      });
    }
  }

  _handleRadioChange = (e, field) => {
    switch (field) {
      case "deleteCompanyOption":
        this.companyOptionData.deleteCompanyOption = e.target.value;
        break;
    }
  };

  _handleCloseModel = () => {
    runInAction(() => {
      this.companyOptionData = { deleteCompanyOption: "childCompanies" };
    });
  };

  _fetchGetChildCompanies() {
    getChildCompanies(Common.token, this.companyId, (err, data) => {
      if (data?.error) {
        toast.error(data?.error);
      } else {
        this.GetChildCompanies = data?.payload;
      }
    });
  }

  _fetchGetAllSameLevelCompanies(companyId) {
    getAllSameLevelCompanies(Common.token, companyId, (err, data) => {
      if (data) {
        this.GetAllSameLevelCompanies = data;
      } else {
        toast.error(err);
      }
    });
  }

  _fetchdeleteCompanyInfo(companyId) {
    deleteCompanyInfo(Common.token, companyId, (err, data) => {
      if (data) {
        toast.success(data.message);
      } else {
        toast.error(err);
      }
    });
  }

  _handleChangeRegisterDate = (value) => {
    this.companyInfo.registerDate = value;
  };

  _handleChangeExpireDate = (value) => {
    this.companyInfo.expireDate = value;
  };

  setCompanyLevel = (value) => {
    this.companyLevel = value;
  };

  setSelectedLevel = (value) => {
    this.selectedLevel = value;
  };

  setSwitchValue = (value) => {
    this.switchValue = value;
  };

  setAssignSwitchValue = (value) => {
    this.switchValue = value;
  };

  setSelectedYear = (value) => {
    this.setLoading(false);
    this.selectedYear = value;
    this._handleGetActivityLogs();
  };
  setSelectedMonth = (value) => {
    this.setLoading(false);
    this.selectedMonth = value;
    this._handleGetActivityLogs();
  };

  // Activity Logs
  _handleGetActivityLogs = () => {
    this.setLoading(true);
    getActivityLogs(
      this.companyId,
      moment(this.startDate).format("YYYY-MM-DD"),
      moment(this.endDate).format("YYYY-MM-DD"),
      Common.token,
      (err, data) => {
        if (data?.message === "Token Expired!") {
          window.location.pathnam = "/";
        } else if (err) {
          toast.error(err);
        } else if (data?.payload === null) {
          this.logsData = [];
          this.setLoading(false);
        } else {
          runInAction(() => {
            this.logsData = data?.payload;
            this.setLoading(false);
          });
        }
      }
    );
  };

  _handleGetLogIssues = () => {
    this.handleSetLoading(true);
    getLogIssues(
      moment(this.startDate).format("YYYY-MM-DD"),
      moment(this.endDate).format("YYYY-MM-DD"),
      this.companyId,
      Common.token,
      (err, data) => {
        if (data?.message === "Token Expired!") {
          window.location.pathnam = "/";
        } else if (data?.payload === null) {
          this.issuesData = [];
          this.handleSetLoading(false);
        } else if (err) {
          toast.error(err);
        } else {
          runInAction(() => {
            this.issuesData = data?.payload;
            this.handleSetLoading(false);
          });
        }
      }
    );
  };

  // Logs Noti
  _handleGetLogsNoti = () => {
    getLogsNoti(this.companyId, Common.token, (err, data) => {
      if (data) {
        runInAction(() => {
          this.logsNoti = data?.payload;

          this.setLoading(true);
        });
      } else {
        toast.error(err.message);
      }
    });
  };

  //issue detail
  _handleIssueDetail = (issueId) => {
    getIssueDetail(issueId, Common.token, (err, data) => {
      if (data) {
        runInAction(() => {
          this.issueDetail = data?.payload;
          this.setLoading(true);
        });
      } else {
        toast.error(err.message);
      }
    });
  };

  // Logs Noti
  _handleGetIssuesNoti = () => {
    getIssuesNoti(Common.companyId, Common.token, (err, data) => {
      if (data) {
        runInAction(() => {
          this.issueNoti = data?.payload;
          console.log(this.issueNoti, "lognoti====>fromapi");
          this.setLoading(true);
        });
      } else {
        toast.error(err.message);
      }
    });
  };

  //   _handleGetAllRelatedCompany =()=>{
  //   runInAction(()=>{
  //     this.relatedCompanies=[]
  //   });

  //   getAllRelatedCompany(Common.companyId, Common.token, (err, data) => {
  //     if (data) {
  //       runInAction(() => {
  //         this.relatedCompanies=data;
  //       });
  //     } else {
  //       toast.error(err.message);
  //     }
  //   });
  //   // console.log(this.relatedCompanies)
  //   }

  _handleReminderCheck(data, userId) {
    editRemiderUser(Common.token, userId, data, (err, data) => {
      if (data) {
        if (data.message === "Token Expired!") window.location.pathname = "/";
        else toast.success(data.message);
        this._fetchUserList(Common.token, this.companyId, Common.role);
      }
    });
  }

  handleReminderSwitch(e, userId) {
    this.userList.find((v) => v.id === userId).remind_email = e.target.checked;
    this._handleReminderCheck(
      {
        remind_email: e.target.checked,
      },
      userId
    );
  }

  _handleUpdateCompanyStatus(companyId, updateStatus) {
    updateCompanyStatus(Common.token, companyId, updateStatus, (err, data) => {
      if (data) {
        this.updateStatus = updateStatus;

        toast.success(data.payload);
        // window.location.pathname = "/co2/admin_panel";
        this._fetchGetChildCompanies();
      } else {
        toast.error(err.message);
      }
    });
  }

  _handleResetPasswordSendEmail(userId) {
    SendEmail(this.token, userId, (err, data) => {
      if (data) {
        toast.success(data.payload);
      } else {
        toast.error(data.payload);
      }
    });
  }
}

export default new AdminPanel();
