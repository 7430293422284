// export const baseURL = "http://172.104.40.242:8000";
// export const baseIMGURL = "http://172.104.40.242:8000/";

// export const baseURL = "https://128.199.244.5:8000";
// export const baseIMGURL = "https://128.199.244.5:8000/";

// export const baseURL = "https://www.co2xconnect.com/api";
// export const baseIMGURL = "https://www.co2xconnect.com/api/";
// export const SSO_URL = "https://www.co2xconnect.com/api/";

// export const baseURL = "https://co2delete.com/api";
// export const baseIMGURL = "https://co2delete.com/api/";
// export const SSO_URL = "https://co2delete.com/api/";

export const baseURL = "https://staging.co2xconnect.com/api";
export const baseIMGURL = "https://staging.co2xconnect.com/api/";
export const SSO_URL = "https://staging.co2xconnect.com/api/";

// // //2023-06-02 05:47:52
// export const baseURL = "/api";
// export const baseIMGURL = "/api/";
// export const SSO_URL = "/api/";

// // export const baseURL = "https://everkumo.com/everkumo-api/co2-service";

// export const baseURL = "http://localhost:8000";
// export const baseIMGURL = "http://localhost:8000/";
// export const SSO_URL = "http://localhost:8000/";

//export const baseURL = "http://192.168.100.40:8000"

// export const baseURL = "http://192.168.1.18:8000"
// export const baseIMGURL = "http://192.168.1.18:8000/"

// export const baseURL = "http://192.168.1.168:8000/"

//User API
export const LoginAPI = `${baseURL}/co2/auth/`;
export const getAllUserAPI = `${baseURL}/co2/user/getAllUser`;
export const createUserAPI = `${baseURL}/co2/user/createUser`;
export const createNewUserAPI = `${baseURL}/co2/user/v2/create_user`;
export const updateUserAPI = `${baseURL}/co2/user/updateUserInfo`;
export const getAllUserListAPI = `${baseURL}/co2/user/v2/userFromCompanies`;

// export const deleteUserAPI = `${baseURL}/co2/user/deleteUser`;
export const deleteUserAPI = `${baseURL}/co2/user/delete_user`;
export const inviteLinkAPI = `${baseURL}/co2/user/create_invite`;
export const registerAPI = `${baseURL}/co2/auth/register`;
export const inviteLinkInfoApi = `${baseURL}/co2/auth/invite`;
export const inviteLinkSendApi = `${baseURL}/co2/user/send_invite`;
export const driverListAPI = `${baseURL}/co2/user/getAllDriver`;
export const logoutAPI = `${baseURL}/co2/auth/logout`;
export const getUserPlanAPI = `${baseURL}/co2/user/plans`;
export const editUserAPI = `${baseURL}/co2/user/updateUserInformation`;
export const updatePlanAPI = `${baseURL}/co2/user/updatePlan`;
export const getUserStatusAPI = `${baseURL}/co2/user/userStatus`;
export const updateUserStatusAPI = `${baseURL}/co2/user/updateUserStatus`;
export const updateUserListAPI = `${baseURL}/co2/user/v2/updateUserInformation`;
export const updateReminderAPI = `${baseURL}/co2/user/v2/updateReminderInformation`;
export const sendEmailAPI = `${baseURL}/co2/user/v2/send-email`;

//Asset API
export const VehicleTripData = `${baseURL}/co2/wailon`;
export const getAllAsset = `${baseURL}/co2/wailon/getAllItem`;
export const getAssetById = `${baseURL}/co2/wailon/getItemById`;
export const getAllDeviceAPI = `${baseURL}/co2/device`;
export const getAllFMSDeviceAPI = `${baseURL}/co2/device/getAllFMSVehicles`;
export const getAllAssetTypeAPI = `${baseURL}/co2/device/assetType/getAllAssetType`;
export const updateDeviceWithIdAPI = `${baseURL}/co2/device/updateDeviceWithId`;
export const getAllScheduleListAPI = `${baseURL}/co2/setting/getAllScheduleList`;
export const getAlgorithmParamAPI = `${baseURL}/co2/setting/getAlgorithmParam`;
export const addNewDeviceAPI = `${baseURL}/co2/device/addNewDevice`;
export const editDeviceAPI = `${baseURL}/co2/device/updateDevice`;
export const editMinMaxDeviceAPI = `${baseURL}/co2/device/updateMinMaxDevice`;
export const removeDeviceAPI = `${baseURL}/co2/device/removeDevice`;
export const issuanceReportAPI = `${baseURL}/co2/report/issuanceReport`;

export const getDeviceThresholdAPI = `${baseURL}/co2/device/threshold-setting`;
export const editDeviceMinMaxThresholdAPI = `${baseURL}/co2/device/threshold-setting-update`;
export const getEmissionTypeUnitsAPI = `${baseURL}/co2/device/emission-type-unit`;

// Company API
// export const getCompany = `${baseURL}/co2/company`;\

export const getCompanyNewAPI = `${baseURL}/co2/company/v2/companyListWithLevel`;
export const getCompany = `${baseURL}/co2/company/v2/company`;
export const getCompanyv1 = `${baseURL}/co2/company`;
export const getCompanyName = `${baseURL}/co2/company/getCompanyName`;
export const UpdateCompanyAPI = `${baseURL}/co2/company/updateCompany`;
export const updateAccIdAPI = `${baseURL}/co2/company/updateAncentAccID`;
export const createNewCompanyAPI = `${baseURL}/co2/company/v2/createNewCompanyInfo`;
export const createCompanyAPI = `${baseURL}/co2/company/v2/createCompany`;
export const createCompanyNewAPI = `${baseURL}/co2/company/v2/create-company`;
export const updateCompanyNewAPI = `${baseURL}/co2/company/v2/updateCompanyInfo`;
export const deleteCompanyAPI = `${baseURL}/co2/company/v2/deleteCompany`;
export const NewUpdateCompanyAPI = `${baseURL}/co2/company/v2/updateCompany`;
export const registerCompanyAPI = `${baseURL}/co2/company/company-registration`;

//Block Chain API
export const getAllContract = `${baseURL}/co2/trackNTrace/getAllTrackNTraceContract`;
export const getAllContractByCompany = `${baseURL}/co2/trackNTrace/getAllTrackNTraceContractByCompany`;
export const contractById = `${baseURL}/co2/trackNTrace/getTrackNTraceDataById`;
export const getContractDetail = `${baseURL}/co2/trackNTrace/getTrackNTraceDetail`;
export const createContractAPI = `${baseURL}/co2/trackNTrace/createTrackNTraceContract`;

//Carbon API
export const DashboardData = `${baseURL}/co2/carbon/dashboard`;
export const FMSDashboardData = `${baseURL}/co2/carbon/fmsdashboard`;
export const TrackingData = `${baseURL}/co2/carbon/tracking`;
export const CertificateData = `${baseURL}/co2/carbon/certificate`;
// export const CertificateList = `${baseURL}/co2/certificate/certificates`;
export const CertificateList = `${baseURL}/co2/certificate/new-certificates`;
export const CertificateListV2 = `${baseURL}/co2/certificate/v2/new-certificates`;
//export const CalculatedDashboardData = `${baseURL}/co2/carbon/v2/dashboard`;
export const CalculatedDashboardData = `${baseURL}/co2/carbon/v2/new-dashboard`;
export const MonthlyCarbonDataAPI = `${baseURL}/co2/carbon/monthly-carbon-data`;
//export const updateCertificatesAPI = `${baseURL}/co2/certificate/update-certificates`;
export const updateCertificatesAPI = `${baseURL}/co2/certificate/new-update-certificates`;
export const updateCertificatesV2API = `${baseURL}/co2/certificate/v2/new-update-certificates`;
export const sendCertificatesAPI = `${baseURL}/co2/certificate/send-certificates`;
export const stacsCertificatesAPI = `${baseURL}/co2/certificate/stacs-certificates`;

//Manual Entry API
export const editManualEntryAPI = `${baseURL}/co2/manualEntry/edit`;
export const getManualEntryAPI = `${baseURL}/co2/manualEntry`;
export const getYearOfMissingData = `${baseURL}/co2/manualEntry/yearOfMissingData`;
export const getFMSDataEntryAPI = `${baseURL}/co2/manualEntry/fms-data`;
export const getManualEntryDataAPI = `${baseURL}/co2/manualEntry/manual-entry-data`;
export const uploadFMSEntryFileAPI = `${baseURL}/co2/manualEntry/fms-upload`;
export const uploadFMSEntryDataAPI = `${baseURL}/co2/manualEntry/fms-entryData`;
export const uploadEntryFileAPI = `${baseURL}/co2/manualEntry/upload`;
export const uploadEntryDataAPI = `${baseURL}/co2/manualEntry/entryData`;
export const deleteFMSEntryFileAPI = `${baseURL}/co2/manualEntry/fms-ocr-remove`;
export const createManualEntryNewAPI = `${baseURL}/co2/manualEntry/create-manual-data`;
export const getUnitMonthByMonthAPI = `${baseURL}/co2/manualEntry/unit-monthByMonth`;
export const getConsumptionByMonthAPI = `${baseURL}/co2/manualEntry/consumption-ByMonth`;
export const getNewConsumptionByMonthAPI = `${baseURL}/co2/manualEntry/new-consumption-ByMonth`;
export const getConsumptionByMonthV2API = `${baseURL}/co2/manualEntry/v2/consumption-ByMonth`;
export const uploadFMSEntryDataV2API = `${baseURL}/co2/manualEntry/v2/manual-data-upload`;
export const actionStatusAPI = `${baseURL}/co2/manualEntry/status-update`;

//Driver
export const vehicleLstAPI = `${baseURL}/co2/driver`;
export const fuelRecordAPI = `${baseURL}/co2/driver/fuel_record`;
// export const fuelHistoryAPI = `${baseURL}/co2/driver/history`
export const fuelHistoryAPI = `${baseURL}/co2/driver/v2/fuel_record`;
export const vehicleInfo = `${baseURL}/co2/driver/vehicle`;
export const vehicleFuelRecordAPI = `${baseURL}/co2/driver/v2/vehicle-fuel-record`;
export const odometerStartEndAPI = `${baseURL}/co2/driver/odometer-start-end`;

//CO2Xlite API
export const createCo2xLiteData = `${baseURL}/co2/co2xLite/create`;
export const getCo2xLiteData = `${baseURL}/co2/co2xLite/data`;
export const deleteCo2xLiteData = `${baseURL}/co2/co2xLite/delete`;
export const updateCo2xLiteData = `${baseURL}/co2/co2xLite/update`;
export const requestReport = `${baseURL}/co2/co2xLite/request_report`;
export const downloadReport = `${baseURL}/co2/co2xLite/download`;
export const liteDashboardData = `${baseURL}/co2/co2xLite/lite_dashboard`;
export const getReports = `${baseURL}/co2/co2xLite/reports`;
export const changeStatus = `${baseURL}/co2/co2xLite/change_status`;
export const getCo2xLiteDataNew = `${baseURL}/co2/co2xLite/lite_data`;
// export const getVehicleData = `${baseURL}/co2/co2xLite/vehicles?companyId=7d021941-26cb-41a6-9b6a-e4ac6762e528`
export const assetAPI = `${baseURL}/co2/co2xLite/lite_create`;
export const getAllLiteAsset = `${baseURL}/co2/co2xLite/vehicles`;

// company yarly data
export const companyYearlyData = `${baseURL}/co2/company/getYears`;

//company Vehicles
export const companyVehiclesForAddNewUser = `${baseURL}/co2/device/v2/getCompanyVehicles`;
export const getUnits = `${baseURL}/co2/device/v2/getVehiclesByUserRole`;
export const assigningVehicle = `${baseURL}/co2/device/v2/assigningVehicles`;
export const assigningUnit = `${baseURL}/co2/company/v2/companyVehicleAssign`;
export const assignUnitList = `${baseURL}/co2/device/v2/assigningUnitForCompany`;
export const lvlCompanies = `${baseURL}/co2/company/lvl-companies`;
export const changeOwner = `${baseURL}/co2/device/change-owner`;

//getChildCompanies
export const getChildCompanies = `${baseURL}/co2/company/v2/getChildCompanies`;

export const getAllSameLevelCompanies = `${baseURL}/co2/company/v2/getAllSameLevelCompanies`;

export const deleteCompanyInfo = `${baseURL}/co2/company/v2/deleteCompanyInfo`;

//getDataImportAccess
export const getDataImportAccess = `${baseURL}/co2/company/v2/getDataImportAccess`;

//leaveUserUnit when they leave from company
export const userUnit = `${baseURL}/co2/user/units`;

//dataImport Api
export const vTripDataImportAPI = `${baseURL}/co2/csvDataImports/v2/importVtrips`;
export const vEngineHourImportAPI = `${baseURL}/co2/csvDataImports/v2/importVEngineHours`;
export const vProfileImportAPI = `${baseURL}/co2/csvDataImports/v2/importVProfiles`;
export const manualEntryDataImportAPI = `${baseURL}/co2/csvDataImports/v2/fmsDataImport`;

//file import history api
export const getImportHistoryAPI = `${baseURL}/co2/csvDataImports/v2/importHistory`;
export const getImportDateHistoryAPI = `${baseURL}/co2/csvDataImports/v2/importDate`;
export const downloadRefFileAPI = `${baseURL}/co2/csvDataImports/v2/downloadRefFile`;

export const downloadImportedFileAPI = `${baseURL}/co2/csvDataImports/v2/downloadCSV`;
export const getImportedUnitsAPI = `${baseURL}/co2/csvDataImports/v2/getImportedUnits`;
export const getImportedTripsAPI = `${baseURL}/co2/csvDataImports/v2/getImportedTrips`;
// export const getImportedEngineHoursAPI = `${baseURL}/co2/csvDataImports/v2/store-engine-hour-data`;
export const getImpotedManualEntryAPI = `${baseURL}/co2/csvDataImports/v2/getImpotedManualEntry`;
export const getTripsFileAPI = `${baseURL}/co2/csv/extract-trips-file`;
export const storeTripsFileAPI = `${baseURL}/co2/csv/store-extract-trip-data`;
export const getUnitProfilesDataAPI = `${baseURL}/co2/csv/extract-unit-file`;
export const storeUnitProfilesDataAPI = `${baseURL}/co2/csv/store-extract-unit-data`;

export const getImportedEngineHoursAPI = `${baseURL}/co2/csv/extract-engine-hour-file`;
export const storeEngineHoursAPI = `${baseURL}/co2/csv/store-engine-hour-data`;

export const deleteManualEntryFileData = `${baseURL}/co2/csv/delete-file-data`;

// create the dataImport api
export const createVProfileAPI = `${baseURL}/co2/csvDataImports/v2/createVProfiles`;
export const createVTripAPI = `${baseURL}/co2/csvDataImports/v2/createVTrips`;
// export const createVEngineHoursAPI = `${baseURL}/co2/csvDataImports/v2/createVEngineHours`;
export const createManualEntryAPI = `${baseURL}/co2/csvDataImports/v2/createMaualEntry`;

// new manual entry api at data import
export const manualEntryCreateNewApi = `${baseURL}/co2/csvDataImports/v2/manualEntryCreate`;
export const getExtractManualFile = `${baseURL}/co2/csv/extract-manual-file`;
export const storeExtractManualData = `${baseURL}/co2/csv/store-extract-manual-data`;

// clear dataImport api
export const clearImportsAPI = `${baseURL}/co2/csvDataImports/v2/clearImports`;

//handleselectvehicle api
export const handleSelectVehicleAPI = `${baseURL}/co2/device/v2/vehicleType`;
export const newUploadEntryFileAPI = `${baseURL}/co2/manualEntry/fms-ocr-upload`;

export const profileAndDailyData = `${baseURL}/co2/device/profileAndDailyData`;
export const profileAndDailyDataV2 = `${baseURL}/co2/device/v2/profileAndDailyData`;
export const vehicleInfoNewAPI = `${baseURL}/co2/driver/v2/vehicle-info`;
//export const monthlyDataAPI = `${baseURL}/co2/certificate/monthly-data`;
export const monthlyDataAPI = `${baseURL}/co2/certificate/new-monthly-data`;
export const monthlyDataV2API = `${baseURL}/co2/certificate/v2/new-monthly-data`;

// logs and issues api
export const getActivityLogsAPI = `${baseURL}/co2/log/logs`;
export const getLogIssuesAPI = `${baseURL}/co2/log/issues`;
export const getIssueDetailAPI = `${baseURL}/co2/log/issue-detail`;

//logs noti api
export const getLogsNotiApi = `${baseURL}/co2/log/notification-logs`;

//issues noti api
export const getIssuesNotiApi = `${baseURL}/co2/log/notification-issue`;

//notifications
export const getAllRelatedCompanyAPI = `${baseURL}/co2/notifications/getAllRelatedCompany`;

//user password reset api
export const userPasswordResetAPI = `${baseURL}/co2/auth/user-password-reset`;

//admin password reset api
export const adminPasswordResetAPI = `${baseURL}/co2/auth/admin-password-reset`;

export const stacsHistoryAPI = `${baseURL}/co2/certificate/stacs-histories`;

export const passwordResetAPI = `${baseURL}/co2/user/password-reset`;

//update company update status
export const updateCompanyStatusAPI = `${baseURL}/co2/company/update-status`;
