import React, { useState } from "react";
import { Colors } from "../Assets/Config/color.config";

export const My_Button = (props) => {
  const {
    text,
    onClick,
    type,
    style,
    small,
    disabled,
    leftIcon,
    rightIcon,
    id,
    // noShadow,
    customColor,
    dataToggle,
    dataTarget,
    dataDismiss,
    className,
    customHoverColor,
    customConfirm,
    customAdd,
    theme
    
  } = props;
  const [isHover, setIsHover] = useState(true);

  const defaultStyle1 = {
    background: customColor || "#0D6E4F",
  };

  const hoverStyle = {
    background: customColor? customHoverColor||customColor : "#0D6E3F",
    // boxShadow: noShadow?'none':`0px 2px 3px ${customColor||Color.SoftBlue}`
  };

  const defaultStyle = isHover || disabled ? defaultStyle1 : hoverStyle;

  const userStyle = style === undefined ? {} : style;

  return (
    <button
      data-dismiss={dataDismiss}
      data-bs-dismiss={dataDismiss}
      disabled={disabled}
      data-target={dataTarget}
      data-bs-target={dataTarget}
      data-toggle={dataToggle}
      data-bs-toggle={dataToggle}
      id={id}
      onClick={onClick}
      type={type === undefined ? "button" : type}
      
      className={`btn btn-block text-light text-center ${
        small ? "py-0" : "p-2"
      } ${className}`}

      style={
        {
        ...defaultStyle,
        ...userStyle,
        outline: "none",
        transition: ".5s",
        // display: "flex",
        // flexDirection: "row",
        // alignItems: "center",
        // justifyContent : "center",
      }}
      onMouseOver={() => setIsHover(false)}
      onMouseLeave={() => setIsHover(true)}
    >
      {leftIcon ? leftIcon : null}
      {text}
      {rightIcon ? <span className="ps-1"> {rightIcon} </span> : null}
      {customConfirm ? <span> {customConfirm}</span> : null}
      {customAdd ? <span>{customAdd}</span> : null}

    </button>
  );
};
